import { Image } from '@mantine/core';
import BlankIcon from 'assets/ask-chai/prompt-ideas/blank-icon.svg';
import styles from './styles.module.scss';

const NoWikiIdea = () => {
  return (
    <div className={styles.emptyStateWrapper}>
      <Image src={BlankIcon} alt='blank icon' w={240} />
      <div className={styles.emptyStateText}>
        Suggested questions on your wiki data is in beta. They&apos;ll begin populating here when
        you add new sources to your wiki
      </div>
    </div>
  );
};

export default NoWikiIdea;
