import checkForElement, { stepConstructor, tutorialIntiator } from 'components/TutorialWrapper';
import TwoIcon from 'assets/ask-chai/two.svg';
import { LATEST_MODEL_UPDATE_MONTH } from 'utils/constants';

export function askChaiTutorialBuilder(showTutorialModal) {
  const steps = [
    {
      element: '#ask-a-question-input-field',
      popover: {
        description: stepConstructor(
          'Ask questions here',
          'Ask questions about your company, team and work. Chai will search across your wiki data to find answers.'
        )
      }
    },
    {
      element: '#select-routing',
      popover: {
        align: 'end',
        description: stepConstructor(
          'Filter your search',
          `Specify Chai to look for answers in your wiki data or generate answers using public GPT-4o data trained up to ${LATEST_MODEL_UPDATE_MONTH}.<br/>In auto mode, Chat Aid himself intelligently determines where should search for answers. Auto mode is in beta.`,
          TwoIcon
        )
      }
    },
    {
      element: '#prompt-ideas',
      popover: {
        description: stepConstructor(
          'Suggested questions',
          'Not sure what questions you can ask Chai? Take inspiration from questions we generated for you.'
        )
      }
    },
    {
      element: '#chat-list',
      popover: {
        description: stepConstructor(
          'Your chats',
          `When you ask questions in a chat, Chai dunderstands that each new question might be related to the previous one.<br/<br/>For a fresh start with new context, create a new chat.`
        )
      }
    }
  ];

  tutorialIntiator(steps, showTutorialModal);
}

export function startAskChaiTutorial(showTutorialModal) {
  checkForElement('ask-a-question-input-field', askChaiTutorialBuilder, showTutorialModal);
}
