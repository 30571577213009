import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useUpdateChatMutation } from 'redux/services/questions';
import {
  setFreeTrialExpired,
  setQuestionsToZero,
  updateQuestionCount
} from 'redux/features/planSlice';
import { useGetCompletionQuery, useGenerateAnswerMutation } from 'redux/services/ask-chai';
import View from './View';
import { COMPLETED_USING, PROMPT_IDEA_TYPES } from 'utils/constants';

const Message = (props) => {
  const { setConversation, completedUsing, canPoll, promptId, messageTs, answerCompletedCallback } =
    props;
  const [showAiAnswer, setShowAiAnswer] = useState(completedUsing === 'ai');
  const [updateChat] = useUpdateChatMutation();
  const { data: completionResponse, isFetching } = useGetCompletionQuery(promptId, {
    skip: !promptId || !canPoll,
    pollingInterval: promptId && canPoll ? 2000 : undefined
  });
  const [generateAnswer] = useGenerateAnswerMutation();
  const dispatch = useDispatch();
  const [searchFilters, setSearchFilters] = useState(null);

  useEffect(() => {
    if (!isFetching) {
      if (completionResponse?.userPrompt) {
        const { userPrompt } = completionResponse;
        setSearchFilters(userPrompt?.llmWiki);

        setConversation((prev) => {
          const conversations = [...prev];
          const index = conversations.findIndex(
            (item) => item.messageTs === messageTs || item.promptId === promptId
          );
          const mutableConversation = { ...conversations[index] };

          mutableConversation.canPoll = false;
          mutableConversation.completion = userPrompt.completion;
          mutableConversation.loading = false;
          mutableConversation.streaming = false;
          mutableConversation.completedUsing = userPrompt.completedUsing;
          mutableConversation.sourcesUsed = userPrompt.sourcesUsed;
          mutableConversation.hasOtherAnswers = userPrompt.hasOtherAnswers;
          mutableConversation.wikiAnswerFound = userPrompt.wikiAnswerFound;
          mutableConversation.queryRoutingCategory = userPrompt.queryRoutingCategory;

          conversations[index] = mutableConversation;
          return conversations;
        });

        setShowAiAnswer(userPrompt.completedUsing === COMPLETED_USING[0]);
        dispatch(updateQuestionCount(userPrompt.creditsUsed));
        answerCompletedCallback(promptId);
      }
    }
  }, [completionResponse, isFetching]);

  async function handleVote(type) {
    setConversation((prev) => {
      const conversations = [...prev];
      const index = conversations.findIndex((item) => item.promptId === promptId);
      const updatedConversation = { ...conversations[index] };
      updatedConversation.upvoted = type === 'upvote';
      updatedConversation.downvoted = type === 'downvote';
      conversations[index] = updatedConversation;
      return conversations;
    });

    updateChat({
      questionId: promptId,
      body: { vote: type === 'upvote' ? 1 : -1 }
    });
  }

  function updateViewWithLoadingState(forcedQueryRoute) {
    setConversation((prev) => {
      const conversations = [...prev];
      const index = conversations.findIndex((item) => item.promptId === promptId);
      const updatedConversation = { ...conversations[index] };
      updatedConversation.hasOtherAnswers = true;
      updatedConversation.loading = true;
      updatedConversation.completedUsing =
        forcedQueryRoute === PROMPT_IDEA_TYPES.COMPANY_WIKI ? 'training' : 'ai';
      updatedConversation.streaming = true;
      updatedConversation.otherCompletions = {
        ai: updatedConversation.otherCompletions?.ai ?? '',
        training: updatedConversation.otherCompletions?.training ?? ''
      };
      conversations[index] = updatedConversation;

      return conversations;
    });
    setShowAiAnswer(forcedQueryRoute === 'OpenAI');
  }

  // Method to update the view with the chunk received from the stream
  function updateAlbusResponse(data) {
    setConversation((prev) => {
      const conversations = [...prev];
      const index = conversations.findIndex(
        (item) => item.messageTs === messageTs || item.promptId === promptId
      );

      if (data?.state === 'User Ineligible') {
        dispatch(setFreeTrialExpired());
      }

      if (data?.state === 'No Questions Left') {
        dispatch(setQuestionsToZero());
      }

      const mutableConversation = { ...conversations[index] };
      mutableConversation.canPoll = true;
      mutableConversation.promptId = data.promptId;
      mutableConversation.loading = true;
      mutableConversation.streaming = true;
      conversations[index] = mutableConversation;
      return conversations;
    });
  }

  async function generateAnotherAnswer(forcedQueryRoute) {
    updateViewWithLoadingState(forcedQueryRoute);
    const body = {
      promptId: promptId,
      forcedQueryRoute,
      entryPoint: 'Web',
      stream: false
    };

    setShowAiAnswer(forcedQueryRoute === 'OpenAI');
    try {
      const response = await generateAnswer(body).unwrap();
      updateAlbusResponse(response);
    } catch (error) {
      toast.error('Oops, something went wrong. Try again!');
    }
  }

  function handleSearchAnswerClick(isDisabled) {
    if (!isDisabled) {
      showAiAnswer
        ? generateAnotherAnswer(PROMPT_IDEA_TYPES.COMPANY_WIKI)
        : generateAnotherAnswer(PROMPT_IDEA_TYPES.OPEN_AI);
    }
  }

  return (
    <View
      handleVote={handleVote}
      generateAnotherAnswer={generateAnotherAnswer}
      showAiAnswer={showAiAnswer}
      setShowAiAnswer={setShowAiAnswer}
      handleSearchAnswerClick={handleSearchAnswerClick}
      llmWiki={searchFilters}
      {...props}
    />
  );
};

Message.propTypes = {
  setConversation: PropTypes.func.isRequired,
  completedUsing: PropTypes.oneOf(['ai', 'training', 'preTraining', 'validation']).isRequired,
  canPoll: PropTypes.bool,
  promptId: PropTypes.string,
  messageTs: PropTypes.string,
  answerCompletedCallback: PropTypes.func.isRequired
};

Message.defaultProps = {
  completedUsing: 'ai',
  canPoll: false,
  promptId: '',
  messageTs: ''
};

export default Message;
