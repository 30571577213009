import PropTypes from 'prop-types';
import { TextInput, Button, Table, Modal, Textarea } from '@mantine/core';
import { useState } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/remove-cross-icon.svg';
import { ReactComponent as SaveIcon } from 'assets/ask-chai/wiki-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import styles from './styles.module.scss';

const View = ({ response, tableKey, tableName, handleSaveClick, handleDeleteTable }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedElements, setEditedElements] = useState(
    response?.data.map((element) =>
      Object.fromEntries(response?.headers.map((header) => [header, element[header] || '']))
    )
  );
  const [editedHeaders, setEditedHeaders] = useState(response?.headers);
  const [description, setDescription] = useState(response?.description);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleEditClick = () => setIsEditing(true);
  const handleDiscardClick = () => {
    setEditedElements(response?.data);
    setEditedHeaders(response?.headers);
    setDescription(response?.description);
    setIsEditing(false);
  };

  const handleInputChange = (index, field, value) => {
    const updatedElements = [...editedElements];
    updatedElements[index][field] = value;
    setEditedElements(updatedElements);
  };

  const handleHeaderInputChange = (index, newHeader) => {
    const updatedHeaders = [...editedHeaders];
    const oldHeader = updatedHeaders[index];
    updatedHeaders[index] = newHeader;

    // Update data keys
    const updatedElements = editedElements.map((row) => {
      const newRow = { ...row };
      newRow[newHeader] = newRow[oldHeader];
      delete newRow[oldHeader];
      return newRow;
    });
    setEditedHeaders(updatedHeaders);
    setEditedElements(updatedElements);
  };

  const handleDeleteClick = (index) => {
    const updatedElements = editedElements.filter((_, i) => i !== index);
    setEditedElements(updatedElements);
  };

  const handleAddRowClick = () => {
    const newRow = editedHeaders.reduce((acc, header) => ({ ...acc, [header]: '' }), {});
    setEditedElements([...editedElements, newRow]);
  };

  const headerCells = editedHeaders.map((header, index) => (
    <th key={index}>
      {isEditing ? (
        <TextInput
          value={header}
          onChange={(e) => handleHeaderInputChange(index, e.target.value)}
        />
      ) : (
        header
      )}
    </th>
  ));

  const rows = editedElements.map((element, index) => (
    <tr key={index}>
      {editedHeaders.map((header) => (
        <Table.Td key={header + index}>
          {isEditing ? (
            <TextInput
              value={element[header]}
              onChange={(e) => handleInputChange(index, header, e.target.value)}
            />
          ) : (
            element[header]
          )}
        </Table.Td>
      ))}
      {isEditing && (
        <Table.Td>
          <DeleteIcon
            width={20}
            onClick={() => handleDeleteClick(index)}
            className={styles.deleteBtn}
          />
        </Table.Td>
      )}
    </tr>
  ));

  return (
    <div className={styles.tableContainer}>
      {openDeleteModal && (
        <Modal
          size={500}
          centered
          opened={true}
          onClose={() => setOpenDeleteModal(false)}
          title='Are you sure you want to delete the entire table?'
        >
          <Button variant='outline' radius={8} onClick={() => setOpenDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            ml={10}
            variant='outline'
            color='red'
            radius={8}
            onClick={() => {
              handleDeleteTable(tableKey);
              setOpenDeleteModal(false);
            }}
          >
            Confirm
          </Button>
        </Modal>
      )}

      <div className={styles.tableName}>{tableName}</div>
      <div className={styles.tableDescription}>
        {isEditing ? (
          <Textarea
            mt={7}
            mb={7}
            autosize
            maxRows={6}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder='Enter table description'
          />
        ) : (
          description
        )}
      </div>
      <div className={styles.options}>
        {!isEditing ? (
          <>
            <Button color='#4a90e2' onClick={handleEditClick} variant='outline' radius={8}>
              <EditIcon width={20} />
              &nbsp;Edit
            </Button>
            <Button
              ml={10}
              onClick={() => setOpenDeleteModal(true)}
              color='red'
              variant='outline'
              radius={8}
            >
              <DeleteIcon width={20} />
              &nbsp;Delete
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => {
                handleSaveClick({
                  editedElements,
                  headers: editedHeaders,
                  tableName: tableKey,
                  description,
                  rawDescription: response.rawDescription
                });
                setIsEditing(false);
              }}
              variant='outline'
              pl={10}
              pr={10}
              radius={8}
              className={styles.saveBtn}
            >
              <SaveIcon width={20} /> Save
            </Button>
            <Button
              ml={10}
              onClick={handleDiscardClick}
              variant='outline'
              radius={8}
              pl={10}
              pr={10}
              className={styles.discardBtn}
            >
              <CrossIcon width={20} /> Discard
            </Button>
          </>
        )}
      </div>
      <div className={styles.tableWrapper}>
        <Table>
          <Table.Thead>
            <Table.Tr>
              {headerCells}
              {isEditing && (
                <Table.Th>
                  <AddIcon width={20} className={styles.addBtn} onClick={handleAddRowClick} />
                </Table.Th>
              )}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </div>
    </div>
  );
};

View.defaultProps = {
  response: {
    data: [],
    headers: [],
    description: '',
    rawDescription: ''
  },
  handleSaveClick: () => {},
  tableKey: '',
  tableName: '',
  handleDeleteTable: () => {}
};

View.propTypes = {
  response: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    headers: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    rawDescription: PropTypes.string
  }),
  tableKey: PropTypes.string,
  tableName: PropTypes.string,
  handleSaveClick: PropTypes.func,
  handleDeleteTable: PropTypes.func
};

export default View;
