import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Loader } from '@mantine/core';
import View from './View.jsx';
import EmptyState from 'pages/ControlPanel/AutoAnswer/EmptyState';
import {
  useFetchSurveyReportsQuery,
  useGenerateComparisonDatasetMutation
} from 'redux/services/survey-reports';
import styles from './styles.module.scss';

const Reports = ({ teamId, report, setReport }) => {
  const { data, isLoading } = useFetchSurveyReportsQuery({ teamId });
  const [generateKeyPoints] = useGenerateComparisonDatasetMutation();

  const handleGenerateComparisonDataset = async (selectedSurveyReports) => {
    try {
      await generateKeyPoints({ teamId, documentIds: selectedSurveyReports }).unwrap();
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  return isLoading ? (
    <div className={styles.loader}>
      <Loader size='lg' />
    </div>
  ) : data?.length > 0 ? (
    <View
      sourcesList={data}
      report={report}
      setReport={setReport}
      handleGenerateComparisonDataset={handleGenerateComparisonDataset}
    />
  ) : (
    <EmptyState text='Survey reports not available!' />
  );
};

Reports.defaultProps = {
  teamId: '',
  report: null,
  setReport: () => {}
};

Reports.propTypes = {
  teamId: PropTypes.string,
  report: PropTypes.object,
  setReport: PropTypes.func
};

export default Reports;
