import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import PropTypes from 'prop-types';
import { Text } from '@mantine/core';
import styles from './styles.module.scss';

const ConfirmationModal = (props) => {
  const { isOpen, onClose, handleCancel, handleChange, prevPersona, nextPersona, isLoading } =
    props;
  return (
    <DeleteWarningModal
      open={isOpen}
      onClose={onClose}
      handleCancel={handleCancel}
      handleDelete={handleChange}
      header={<Text className={styles.header}>Change persona</Text>}
      title={
        <Text className={styles.title}>
          Are you sure you want to change Chai&apos;s persona from <b>{prevPersona}</b> to&nbsp;
          <b>{nextPersona}</b> ?
        </Text>
      }
      body={
        <Text className={styles.body}>
          <span>Note:</span> all your teammates will see this change in persona when they receive
          responses from Chat Aid .
        </Text>
      }
      removeText='Confirm'
      customClass={{ cancelBtn: styles.cancelBtn, confirmBtn: styles.confirmBtn }}
      loading={isLoading}
    />
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  prevPersona: PropTypes.string.isRequired,
  nextPersona: PropTypes.string.isRequired,
  isLoading: PropTypes.bool
};

export default ConfirmationModal;
