import React, { useEffect } from 'react';
import { bool, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const CustomNavigate = ({ to, replace, ...rest }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const queryParams = new URLSearchParams(location.search);
  const queryParamString = queryParams.toString();

  const updatedTo = queryParamString ? `${to}?${queryParamString}` : to;

  useEffect(() => {
    if (!user.isAdmin || !user.isTeamAdmin) {
      navigate('/ask-chai');
    }
  }, []);

  return <Navigate to={updatedTo} replace={replace} {...rest} />;
};

CustomNavigate.propTypes = {
  to: string,
  replace: bool
};

export default CustomNavigate;
