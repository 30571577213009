import { Paper, Text } from '@mantine/core';
import messageEmptyStateLogo from 'assets/components/messageEmptyStateLogo.svg';
import styles from './styles.module.scss';

const MessageEmptyState = () => {
  function learnMoreClickHandler() {
    window.open('https://docs.chataid.com/albus-for-slack/mark-important', '_blank');
  }

  return (
    <Paper className={styles.paper}>
      <img src={messageEmptyStateLogo} className={styles.messageImage} alt='Empty State Logo' />
      <Text className={styles.text}>
        When you mark a message as important on Slack, they will be displayed here.
      </Text>
      <Text className={styles.learnMore} onClick={learnMoreClickHandler}>
        Learn more.
      </Text>
    </Paper>
  );
};

export default MessageEmptyState;
