import PropTypes from 'prop-types';
import { Anchor, Flex, Image } from '@mantine/core';
import AddSourceIllustration from 'assets/onboarding/add-source-illustration.svg';
import AICPASOC from 'assets/connectors/aicpa-soc.svg';
import ISO27001 from 'assets/connectors/iso-27001.svg';
import ISO27701 from 'assets/connectors/iso-27701.svg';
import BgStars from 'assets/onboarding/bg-stars-halfpage.svg';
import GDPR from 'assets/connectors/gdpr.svg';
import { STEPS } from 'pages/OnBoarding/constants';
import styles from './styles.module.scss';

const InformationContainer = ({ step }) => {
  return (
    <div className={styles.wrapper}>
      <Image src={BgStars} className={styles.bgStars} />
      {step === STEPS.ZERO ? (
        <Flex direction='column' gap={5} className={styles.textContainer}>
          <div>
            <h1>Search through all your company&apos;s apps in one place.</h1>
            <p>
              Chat Aid remembers information from all your apps, continuously learns about your
              company&apos;s unique context and helps you find verifiable, holistic answers in
              seconds.
            </p>
          </div>
          <div>
            <h1>We take data security very seriously.</h1>
            <p>
              Chat Aid is SOC 2 Type II certified, ISO 27001 certified, ISO 27701 certified and GDPR
              compliant.&nbsp;
              <Anchor
                target='_blank'
                href='https://docs.chataid.com/albus-for-slack/data-privacy-and-security'
              >
                Learn more
              </Anchor>
              .
            </p>
          </div>
          <div className={styles.certifications}>
            <Image className={styles.companyLogo} src={AICPASOC} />
            <Image className={styles.companyLogo} src={ISO27001} />
            <Image className={styles.companyLogo} src={ISO27701} />
            <Image className={styles.companyLogo} src={GDPR} />
          </div>
        </Flex>
      ) : (
        <Flex justify='center' align='center' h='100%' pt={40}>
          <Image src={AddSourceIllustration} w={250} h={606} />
        </Flex>
      )}
    </div>
  );
};

InformationContainer.propTypes = {
  step: PropTypes.string.isRequired
};

export default InformationContainer;
