import { Checkbox, LoadingOverlay, Switch } from '@mantine/core';
import classNames from 'classnames';
import styles from './GlobalSettings.module.scss';
import { bool, func } from 'prop-types';

const GlobalSettings = (props) => {
  const {
    loading,
    inProgress,
    autoAnswer,
    autoEnable,
    onAutoAnswerSettingChanged,
    onAutoEnableSettingChanged
  } = props;

  return (
    <div className={styles.globalEnableWrapper}>
      <LoadingOverlay visible={loading} />
      <div className={styles.enableSettingWrapper}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>Auto-answer</div>
          <div className={styles.description}>
            When enabled, Chai will listen to every message on channels he&apos;s a member of. He
            will proactively participate in conversations to clear blockers, add contexts, answer
            questions and more.
          </div>
        </div>
        <Switch
          size='md'
          disabled={inProgress}
          checked={autoAnswer}
          onChange={onAutoAnswerSettingChanged}
          classNames={{
            track: classNames(styles.switch, { [styles.checked]: autoAnswer }),
            thumb: styles.thumb
          }}
        />
      </div>
      {autoAnswer && (
        <div className={styles.autoEnableSettingWrapper}>
          <Checkbox
            disabled={inProgress}
            radius={'sm'}
            size={'xs'}
            label='Enable auto-answer when Chai is added to a new channel'
            checked={autoEnable}
            onChange={onAutoEnableSettingChanged}
            classNames={{
              label: styles.checkboxLabel,
              body: styles.checkboxBody,
              input: classNames(styles.defaultCheckboxInput, {
                [styles.checkboxInput]: autoEnable
              }),
              inner: styles.checkboxInner
            }}
          />
        </div>
      )}
    </div>
  );
};

GlobalSettings.propTypes = {
  loading: bool,
  inProgress: bool,
  autoAnswer: bool.isRequired,
  autoEnable: bool.isRequired,
  onAutoAnswerSettingChanged: func.isRequired,
  onAutoEnableSettingChanged: func.isRequired
};

GlobalSettings.defaultProps = {
  loading: false,
  inProgress: false
};

export default GlobalSettings;
