import { useEffect, useState } from 'react';
import {
  Accordion,
  Anchor,
  Button,
  Divider,
  Flex,
  Grid,
  Skeleton,
  Text,
  Image,
  Tooltip,
  Badge
} from '@mantine/core';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mantine/hooks';
import classNames from 'classnames';
import AnswerForm from './AnswerForm';
import AnswerPreview from './AnwerPreview';
import AnswerTabs from './AnswerTabs';
import ProfilePicture from 'components/ProfilePicture';
import Popover from 'components/Popover';
import InfoIcon from 'assets/ask-chai/info-icon.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as ExportIcon } from 'assets/icons/export.svg';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import localStorage from 'utils/localStorage';
import { CHANNEL_TYPE, QUESTIONS_PAGE_TABS } from 'utils/constants';
import { formatDate, convertSlackLinks } from 'utils/formatters';
import answerListStyles from './styles.module.scss';
import styles from '../../../styles.module.scss';

const UserPromptFormatted = ({ text }) => {
  const components = convertSlackLinks(text);

  return (
    <>
      {components.map((component, index) => {
        if (typeof component === 'string') {
          return <span key={index}>{component}</span>;
        } else {
          return (
            <Anchor key={index} size='sm' href={component.link} target='_blank' underline='always'>
              {component.name}
            </Anchor>
          );
        }
      })}
    </>
  );
};

const AnswerItem = (props) => {
  const {
    data,
    isFirstIndex,
    isOnlyQuestion,
    isOnlyEscalatedQuestion,
    value,
    loading,
    tab,
    handleResolve,
    handleAnswerButtonClick
  } = props;
  const getChannelIcon = (channelType) =>
    channelType === CHANNEL_TYPE.PRIVATE ? (
      <>
        <LockIcon />
        &nbsp;
      </>
    ) : (
      '#'
    );
  const [showAnswerForm, setShowAnswerForm] = useState(false);
  const screen767 = useMediaQuery('(max-width: 767px)');
  const pannelOpen = value.includes(data._id);
  const isEscalated =
    tab === QUESTIONS_PAGE_TABS.NEEDS_ATTENTION && data.escalation.status === 'active';
  const [showAnswerPopover, setShowAnswerPopover] = useState(false);
  const seenAnswerModalCount = Number(localStorage.get('seenAnswerModalCount')) ?? 0;

  useEffect(() => {
    if ((!seenAnswerModalCount || seenAnswerModalCount < 3) && isFirstIndex) {
      setTimeout(() => {
        setShowAnswerPopover(true);
      }, 500);
    }
  }, []);

  const handleAnswerPopoverClose = () => {
    setShowAnswerPopover(false);
    localStorage.set('seenAnswerModalCount', seenAnswerModalCount + 1);
  };

  const tooltipLabelSwitcher = (type) => {
    switch (type) {
      case CHANNEL_TYPE.PRIVATE:
        return 'A colleague asked this question in a private channel you are not a member of';
      case CHANNEL_TYPE.PERSONAL:
        return 'A colleague asked this question in their DM with Chai';
      case CHANNEL_TYPE.GROUP:
        return 'A colleague asked this question in a group DM with Chai';
      default:
        return '';
    }
  };

  const channelNameFormatSwitcher = (channelName, channelType) => {
    switch (channelType) {
      case CHANNEL_TYPE.PRIVATE:
      case CHANNEL_TYPE.PUBLIC:
        return (
          <>
            {getChannelIcon(data?.channel?.type)}
            {channelName}
          </>
        );
      case CHANNEL_TYPE.PERSONAL:
        return 'Chai DM';
      case CHANNEL_TYPE.GROUP:
        return 'Group DM';
      default:
        return channelName;
    }
  };

  return (
    <>
      <Flex align='center' justify='space-between' mr={screen767 ? 0 : 35}>
        {loading ? (
          <Skeleton height={40} width={200} radius={2} />
        ) : (
          <Flex gap={12}>
            <ProfilePicture photoUrl={data?.user?.photo} name={data?.user?.name} />
            <div>
              <Text
                className={classNames(styles.userName, 'capitalize-first-letter', styles.leftAlign)}
              >
                <b>{data.user.name}</b>
              </Text>
              <Text className={styles.askedAt}>{formatDate(data.askedAt)}</Text>
            </div>
          </Flex>
        )}
        {loading ? (
          <Skeleton height={40} width={200} radius={2} />
        ) : (
          isFirstIndex && (
            <Flex direction='column' justify='flex-end' mr={screen767 ? 10 : 0}>
              <Text className={styles.userName}>
                <b>View entire conversation:</b>
              </Text>
              {data.channel.link && !data.redacted ? (
                <Anchor
                  size='sm'
                  href={data.channel.link}
                  target='_blank'
                  underline='always'
                  className='font-poppins'
                >
                  {getChannelIcon(data?.channel?.type)}
                  {data.channel.name} <ExportIcon />
                </Anchor>
              ) : (
                <Flex align='center'>
                  <Text
                    className={classNames(
                      'font-poppins',
                      data.channel.type === CHANNEL_TYPE.PRIVATE ? styles.disabledChannel : ''
                    )}
                    size='xs'
                  >
                    {channelNameFormatSwitcher(data.channel.name, data.channel.type)}
                  </Text>
                  {data.channel.type !== CHANNEL_TYPE.PUBLIC &&
                  data.channel.type !== CHANNEL_TYPE.WEB ? (
                    <Tooltip
                      label={tooltipLabelSwitcher(data.channel.type)}
                      w={274}
                      arrowPosition='center'
                      position='top-start'
                      className='tooltip-custom'
                    >
                      <Image src={InfoIcon} w={16} />
                    </Tooltip>
                  ) : null}
                </Flex>
              )}
            </Flex>
          )
        )}
      </Flex>
      <Accordion.Item value={data._id} mr={screen767 ? 0 : 35} mt={12} mb={10}>
        {loading ? (
          <Skeleton height={200} width={680} radius={2} />
        ) : (
          <Accordion.Control
            className={styles.prompt}
            classNames={{
              control: styles.accordionControl,
              chevron: !pannelOpen && styles.accordionChevron
            }}
          >
            <Grid>
              <Grid.Col
                span={screen767 ? 6 : 'auto'}
                className={classNames('font-poppins', 'capitalize-first-letter')}
                weight={500}
              >
                <b>
                  <UserPromptFormatted text={data.prompt} />
                </b>
              </Grid.Col>
              {showAnswerForm ? null : (
                <Grid.Col
                  span={screen767 ? 6 : 'content'}
                  className={classNames(styles.accordion, 'font-poppins')}
                  style={{
                    padding: screen767 ? 0 : 8,
                    paddingTop: screen767 ? 0 : 8,
                    marginLeft: screen767 ? 8 : 0,
                    textAlign: screen767 ? 'start' : 'end',
                    minWidth: 50
                  }}
                >
                  {pannelOpen ? 'Hide' : 'Show'}
                </Grid.Col>
              )}
            </Grid>
          </Accordion.Control>
        )}
        {loading ? (
          <Skeleton />
        ) : isEscalated && !showAnswerForm ? (
          <Text className={answerListStyles.escalationAlertLabel}>
            <SVGIcon name={ICONS_LIST.TOOL_TIP} height={18} width={18} />
            Teammates asked for help here.
          </Text>
        ) : null}
        {showAnswerForm ? (
          <AnswerForm
            handleCancel={() => {
              setShowAnswerForm(false);
              handleAnswerButtonClick(false);
            }}
            handleAnswer={(value) => {
              setShowAnswerForm(false);
              handleAnswerButtonClick(false);
              handleResolve(
                {
                  promptId: data._id,
                  answer: value
                },
                isOnlyEscalatedQuestion
              );
            }}
          />
        ) : (
          <Accordion.Panel>
            {data.completion?.admin ? (
              <AnswerTabs data={data} screen767={screen767} />
            ) : (
              <Flex className={styles.answerVoteWrapper}>
                <div className={styles.accordianContentWrapper}>
                  <AnswerPreview
                    data={data}
                    screen767={screen767}
                    answer={
                      data?.completion?.[data.completedUsing] ||
                      'We could not find an answer in wiki'
                    }
                  />
                </div>
                <Flex
                  justify='space-between'
                  direction={screen767 ? 'column' : 'row'}
                  gap={10}
                  mb={screen767 ? 16 : 26}
                >
                  <div>
                    <div className={styles.feedback}>
                      Is the answer correct? Your teammates said -
                    </div>
                    <Flex align='center' gap={7}>
                      <Text>
                        <Badge className={styles.feedbackBadge}>Yes ({data.upvotes.length})</Badge>
                      </Text>
                      <Text>
                        <Badge className={styles.feedbackBadge}>No ({data.downvotes.length})</Badge>
                      </Text>
                    </Flex>
                  </div>

                  <Flex gap={20} align='center'>
                    {isEscalated ? (
                      <Text
                        className={styles.markedResolved}
                        onClick={() =>
                          handleResolve(
                            { promptId: data._id, resolveQuestion: true },
                            isOnlyEscalatedQuestion
                          )
                        }
                      >
                        Mark as resolved
                      </Text>
                    ) : null}
                    <Popover
                      open={pannelOpen && showAnswerPopover}
                      heading='✨ Make Chai answers better'
                      body='Answer questions to help your teammates and teach Chai how to answer more accurately next time.'
                      handleClose={handleAnswerPopoverClose}
                      handleChange={() => setShowAnswerPopover(true)}
                      hideCloseBtn={seenAnswerModalCount >= 3}
                    >
                      <Button
                        className='primaryBtnOutline'
                        onClick={() => {
                          setShowAnswerPopover(false);
                          setShowAnswerForm(true);
                          handleAnswerButtonClick(true);
                        }}
                        onMouseEnter={() => seenAnswerModalCount >= 3 && setShowAnswerPopover(true)}
                        onMouseLeave={() =>
                          seenAnswerModalCount >= 3 && setShowAnswerPopover(false)
                        }
                      >
                        Answer
                      </Button>
                    </Popover>
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Accordion.Panel>
        )}
        {isOnlyQuestion ? null : <Divider />}
      </Accordion.Item>
    </>
  );
};

AnswerItem.default = {
  isOnlyEscalatedQuestion: false
};

AnswerItem.propTypes = {
  data: PropTypes.object,
  isFirstIndex: PropTypes.bool,
  isOnlyQuestion: PropTypes.bool,
  value: PropTypes.array,
  loading: PropTypes.bool,
  tab: PropTypes.string.isRequired,
  handleResolve: PropTypes.func.isRequired,
  handleAnswerButtonClick: PropTypes.func.isRequired,
  isOnlyEscalatedQuestion: PropTypes.bool
};

UserPromptFormatted.propTypes = {
  text: PropTypes.string.isRequired
};

export default AnswerItem;
