import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, TagsInput, Text, Button, Flex, Table, Checkbox, Alert } from '@mantine/core';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove-cross-icon.svg';
import { ReactComponent as WarningTriangle } from 'assets/icons/warning-triangle.svg';
import { maybePluralize } from 'utils/utilities';
import styles from './styles.module.scss';

const View = (props) => {
  const {
    isLoading,
    getMoreLicensesLoading,
    sendWithoutLicensesLoading,
    open,
    handleClose,
    data,
    error,
    tableData,
    handleTagsInputChange,
    handleCreateEmail,
    handleAddButtonClick,
    handleCheckboxChange,
    handleDeleteEmail,
    isTableVisible,
    handleCancelClick,
    handleSendClick,
    shouldShowNewCTA,
    handleSendWithoutLicensesClick,
    handleGetMoreLicensesClick,
    enoughLicenses
  } = props;

  const totalSelectedEmailsInTable = tableData.filter((email) => email.selected).length;
  const placeholder = data.length === 0 ? 'jane.doe@gmail.com, saloni.rai@gmail.com...' : '';

  return (
    <Modal
      centered
      opened={open}
      classNames={{ body: styles.modalBody, title: styles.modalTitle, close: styles.closeIcon }}
      closeOnClickOutside={false}
      size={750}
      className='mobile-modal'
      title={
        <>
          Invite your teammates to join your company on Chat Aid via email
          {isTableVisible && tableData.length > 0 ? (
            <Text c='dimmed' size='sm' className={styles.text}>
              When you invite teammates, they will receive an invitation on their email ID, allowing
              them to join your company on Chat Aid .
            </Text>
          ) : (
            <Text c='dimmed' size='sm' className={styles.text}>
              Enter the emails of teammates you&apos;d like to invite to Chat Aid .
            </Text>
          )}
        </>
      }
      onClose={handleClose}
    >
      <Flex mt={15} gap={10} wrap='wrap'>
        <div className={styles.tagsInputContainer}>
          <TagsInput
            classNames={{
              input: styles.input
            }}
            data={[]}
            splitChars={[',', ' ']}
            placeholder={placeholder}
            radius='sm'
            onOptionSubmit={handleCreateEmail}
            onChange={handleTagsInputChange}
            error={error}
            value={data}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.addButton}
            onClick={handleAddButtonClick}
            disabled={!data.length || error}
          >
            Add
          </Button>
        </div>
      </Flex>

      {isTableVisible && tableData.length > 0 && (
        <>
          <div className={styles.tableView}>
            <Text size='sm' fw={700} className={styles.title}>
              Teammates added:
            </Text>
            {shouldShowNewCTA && totalSelectedEmailsInTable > enoughLicenses && (
              <Alert
                icon={<WarningTriangle size={14} />}
                classNames={{
                  root: styles.alert,
                  message: styles.alertMessage,
                  wrapper: styles.alertWrapper,
                  icon: styles.alertIcon
                }}
              >
                {enoughLicenses > 0
                  ? `You can assign only up to ${enoughLicenses} 
      ${maybePluralize(enoughLicenses, 'license')}.`
                  : `You have 0 licenses available to assign.`}
              </Alert>
            )}
            <Table verticalSpacing='sm' classNames={{ tbody: styles.tbody }}>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th className={styles.tableHeaders}>Email</Table.Th>
                  <Table.Th className={classNames(styles.tableHeaders, styles.leftAdjustedHeader)}>
                    Assign License&nbsp;
                    <span className={styles.tableHeaderLabel}>
                      ({totalSelectedEmailsInTable} selected)
                    </span>
                  </Table.Th>
                  <Table.Th></Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {tableData.map((rowData, index) => (
                  <Table.Tr
                    key={index}
                    className={`${styles.tableRows} ${rowData.selected ? styles.selectedRows : ''}`}
                  >
                    <Table.Td>{rowData.email}</Table.Td>
                    <Table.Td>
                      <Checkbox
                        classNames={{ input: styles.checkbox }}
                        checked={rowData.selected}
                        onChange={() => handleCheckboxChange(index)}
                        color='green'
                        radius='sm'
                      />
                    </Table.Td>
                    <Table.Td>
                      <RemoveIcon
                        onClick={() => handleDeleteEmail(index)}
                        className={styles.removeEmail}
                      />
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </div>
          <Flex mt={15} gap={10} justify='flex-end'>
            {shouldShowNewCTA ? (
              <>
                <Button
                  loading={sendWithoutLicensesLoading}
                  variant='outline'
                  onClick={handleSendWithoutLicensesClick}
                  classNames={{ root: styles.cancelButton, label: styles.cancelButtonLabel }}
                >
                  Send invites without licenses
                </Button>
                <Button
                  loading={getMoreLicensesLoading}
                  onClick={handleGetMoreLicensesClick}
                  className={styles.sendButton}
                >
                  Get more licenses and invite
                </Button>
              </>
            ) : (
              <>
                <Button onClick={handleCancelClick} className={styles.cancelButton}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSendClick}
                  className={styles.sendButton}
                  disabled={!tableData}
                  loading={isLoading}
                >
                  Send
                </Button>
              </>
            )}
          </Flex>
        </>
      )}
    </Modal>
  );
};

View.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  sendWithoutLicensesLoading: PropTypes.bool.isRequired,
  getMoreLicensesLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  error: PropTypes.string,
  tableData: PropTypes.array.isRequired,
  handleTagsInputChange: PropTypes.func.isRequired,
  handleCreateEmail: PropTypes.func.isRequired,
  handleAddButtonClick: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleDeleteEmail: PropTypes.func.isRequired,
  isTableVisible: PropTypes.bool.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  handleSendClick: PropTypes.func.isRequired,
  shouldShowNewCTA: PropTypes.bool.isRequired,
  handleSendWithoutLicensesClick: PropTypes.func.isRequired,
  handleGetMoreLicensesClick: PropTypes.func.isRequired,
  enoughLicenses: PropTypes.number.isRequired
};

export default View;
