import { useEffect, useState } from 'react';
import { number, object, string } from 'prop-types';
import FullPreview from './FullPreview.jsx';
import { THEMES } from '../data.jsx';
import SmallPreview from './SmallPreview.jsx';

const WidgetPreview = (props) => {
  const { width, height } = props;
  const [noPreview, setNoPreview] = useState(false);

  useEffect(() => {
    const checkSize = () => {
      const element = document.getElementById('widget-preview');
      const parentElement = element.parentElement;
      const parentWidth = parentElement.offsetWidth;
      setNoPreview(width + 30 > parentWidth || height > 600);
    };
    checkSize();
    window.addEventListener('resize', checkSize);
    return () => {
      window.removeEventListener('resize', checkSize);
    };
  }, [width, height]);

  return (
    <>
      <FullPreview {...props} show={noPreview} />
      <SmallPreview {...props} show={!noPreview} inModal={false} />
    </>
  );
};

WidgetPreview.defaultProps = {
  name: ' Chat Aid ',
  welcomeMessage: 'Hi there, how can I help you?',
  botMessage: "Ask me a question on your wiki and I'll search...",
  position: 'bottom-right',
  width: 360,
  height: 600,
  theme: THEMES.LIGHT
};

WidgetPreview.propTypes = {
  name: string,
  welcomeMessage: string,
  botMessage: string,
  position: string,
  width: number,
  height: number,
  theme: object
};

export default WidgetPreview;
