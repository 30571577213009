import { Anchor, Center, Container, Flex, Group, Card as MantineCard, Text } from '@mantine/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ProfilePicture from 'components/ProfilePicture';
import { formatDate, convertSlackLinks } from 'utils/formatters';
import { ReactComponent as ExportIcon } from 'assets/icons/export.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { CHANNEL_TYPE } from 'utils/constants';
import styles from '../../styles.module.scss';

const UserPromptFormatted = ({ text }) => {
  const components = convertSlackLinks(text);

  return (
    <>
      {components.map((component, index) => {
        if (typeof component === 'string') {
          return <span key={index}>{component}</span>;
        } else {
          return (
            <Anchor key={index} size='sm' href={component.link} target='_blank' underline='always'>
              {component.name}
            </Anchor>
          );
        }
      })}
    </>
  );
};

const Card = ({ data, onClick }) => {
  const getChannelIcon = (channelType) =>
    channelType === CHANNEL_TYPE.PRIVATE ? (
      <>
        <LockIcon />
        &nbsp;
      </>
    ) : (
      '#'
    );

  const channelNameFormatSwitcher = (channelName, channelType) => {
    switch (channelType) {
      case CHANNEL_TYPE.PRIVATE:
      case CHANNEL_TYPE.PUBLIC:
        return (
          <Center>
            {getChannelIcon(data?.channel?.type)}
            {channelName}
          </Center>
        );
      case CHANNEL_TYPE.PERSONAL:
        return 'Chai DM';
      case CHANNEL_TYPE.GROUP:
        return 'Group DM';
      case CHANNEL_TYPE.WEB:
        return 'Web dashboard';
      default:
        return channelName;
    }
  };

  return (
    <MantineCard onClick={() => onClick(data._id)} className={styles.card}>
      <Flex style={{ height: '100%' }} direction='column' justify='space-between'>
        <Container m={0} p={16}>
          <Group justify='apart' mt='md' mb='xs'>
            <Text className={styles.askedAt}>{formatDate(data.askedAt)}</Text>
          </Group>
          <Text
            mt={11}
            className={classNames(styles.prompt, 'capitalize-first-letter', 'truncated-text')}
          >
            <UserPromptFormatted text={data.prompt} />
          </Text>
        </Container>
        <Flex justify='space-between' className={styles.footer}>
          <Flex align='center'>
            <ProfilePicture photoUrl={data?.user?.photo} name={data?.user?.name} />
            <Text className={classNames(styles.userName, 'capitalize-first-letter')} ml={10}>
              {data.user.name}
            </Text>
          </Flex>
          <Flex direction='column' justify='flex-end'>
            <Text className={classNames(styles.userName, 'capitalize-first-letter')}>
              {data.channel.type === CHANNEL_TYPE.WEB ? 'Asked by:' : 'Asked in:'}
            </Text>
            {data.channel.link && !data.redacted ? (
              <Anchor
                size='xs'
                className='font-poppins'
                href={data.channel.link}
                target='_blank'
                underline='always'
              >
                {getChannelIcon(data?.channel?.type)}
                {data.channel.name} <ExportIcon />
              </Anchor>
            ) : (
              <Text
                size='xs'
                className={classNames(
                  data.channel.type !== CHANNEL_TYPE.WEB ? styles.disabledChannel : ''
                )}
              >
                {channelNameFormatSwitcher(data.channel.name, data.channel.type)}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
    </MantineCard>
  );
};

UserPromptFormatted.propTypes = {
  text: PropTypes.string.isRequired
};

Card.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func
};

export default Card;
