import { useState } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import { useAddOnQuestionsMutation } from 'redux/services/ask-chai';

const BuyAddOnQuestionsModal = (props) => {
  const { showAddOnQuestionsModal, setShowAddOnQuestionsModal } = props;
  const [addOnQuestions] = useAddOnQuestionsMutation();
  const [isAddingQuestions, setIsAddingQuestions] = useState(false);

  async function handleConfirm() {
    setIsAddingQuestions(true);
    await addOnQuestions();
    setShowAddOnQuestionsModal(false);
    setIsAddingQuestions(false);
  }

  function handleCancel() {
    setShowAddOnQuestionsModal(false);
  }

  return (
    <View
      open={showAddOnQuestionsModal}
      handleConfirm={handleConfirm}
      handleCancel={handleCancel}
      onClose={handleCancel}
      disabled={isAddingQuestions}
      loading={isAddingQuestions}
    />
  );
};

BuyAddOnQuestionsModal.propTypes = {
  showAddOnQuestionsModal: PropTypes.bool.isRequired,
  setShowAddOnQuestionsModal: PropTypes.func.isRequired
};

export default BuyAddOnQuestionsModal;
