import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Flex, Group, LoadingOverlay, Pagination } from '@mantine/core';
import { usePagination } from '@mantine/hooks';
import { toast } from 'react-toastify';
import pluralize from 'pluralize';
import {
  useGetSettingsQuery,
  useUpdateSettingMutation,
  useGetAutoAnswerChannelsQuery,
  useUpdateAutoAnswerChannelSettingsMutation
} from 'redux/services/settings.js';
import MultiSelectConfirmationBar from 'components/MultiSelectConfirmationBar';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import AddChannelsModal from './AddChannelsModal';
import GlobalSettings from './GlobalSettings';
import FilterSection from './FilterSection';
import ChannelsTable from './ChannelsTable';
import EmptyState from './EmptyState';
import styles from './styles.module.scss';

const ROWS_PER_PAGE = 10;

const AutoAnswer = () => {
  const { data: settings = {}, isFetching: isFetchingSettings } = useGetSettingsQuery();
  const { isFetching: isFetchingAutoAnswerChannels } = useGetAutoAnswerChannelsQuery();
  const [updateSettings, { isLoading: isUpdatingSettings }] = useUpdateSettingMutation();
  const [updateAutoAnswerChannelSettings] = useUpdateAutoAnswerChannelSettingsMutation();
  const [channelFilter, setChannelFilter] = useState('all');
  const [search, setSearch] = useState('');
  const [selectedForDisable, setSelectedForDisable] = useState([]);
  const {
    autoAnswer: { channels: autoAnswerChannels }
  } = useSelector((state) => state.settings);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [page, onChange] = useState(1);
  const [showAddChannelsModal, setShowAddChannelsModal] = useState(false);
  const channel = window.location.hash.replace('#', '');

  useEffect(() => {
    if (channel) {
      setChannelFilter(channel);
    }
  }, [channel]);

  const { autoAnswer } = settings;

  const handleSettingsChanged = async ({ enabled, autoEnable }) => {
    const payload = {
      autoAnswer: {
        enabled,
        autoEnable
      },
      ...(enabled !== undefined && { autoSuggest: { enabled } })
    };
    setInProgress(true);
    const response = await updateSettings(payload);
    setInProgress(false);
    if (response.data) {
      if (enabled !== undefined) {
        toast.success(enabled ? 'Auto-answer enabled' : 'Auto-answer disabled');
      }
    }
  };

  const handleAutoAnswerForChannels = async (channels, autoAnswer) => {
    setInProgress(true);
    await updateAutoAnswerChannelSettings({
      autoAnswer,
      channels
    });
    setInProgress(false);
    setSelectedForDisable([]);
  };

  let channelsToDisplay = autoAnswerChannels ?? [];
  if (channelFilter !== 'all') {
    channelsToDisplay = channelsToDisplay.filter((channel) => channel.type === channelFilter);
  }
  if (search) {
    channelsToDisplay = channelsToDisplay.filter((channel) =>
      channel.name.toLowerCase().includes(search.toLowerCase())
    );
  }

  const allSelected = selectedForDisable.length === (autoAnswerChannels.length ?? 0);
  const someSelected =
    selectedForDisable.length > 0 &&
    selectedForDisable.length < autoAnswerChannels.length &&
    !allSelected;

  const hasPagination = autoAnswerChannels.length > ROWS_PER_PAGE;
  const totalPages = Math.ceil(channelsToDisplay.length / ROWS_PER_PAGE);
  const pagination = usePagination({
    total: Math.ceil(channelsToDisplay.length / ROWS_PER_PAGE),
    page,
    onChange,
    siblings: 2
  });
  const pageChannels = channelsToDisplay.slice(
    (pagination.active - 1) * ROWS_PER_PAGE,
    pagination.active * ROWS_PER_PAGE
  );

  return (
    <>
      <div className={styles.container}>
        <GlobalSettings
          autoAnswer={autoAnswer?.enabled}
          autoEnable={autoAnswer?.autoEnable}
          inProgress={isUpdatingSettings}
          loading={isFetchingSettings}
          onAutoAnswerSettingChanged={async (event) => {
            if (!event.currentTarget.checked && autoAnswerChannels.length) {
              setShowDisableModal(true);
            } else {
              await handleSettingsChanged({ enabled: event.currentTarget.checked });
              setSelectedForDisable([]);
            }
          }}
          onAutoEnableSettingChanged={(e) =>
            handleSettingsChanged({ autoEnable: e.currentTarget.checked })
          }
        />
        {autoAnswer?.enabled && (
          <>
            <LoadingOverlay visible={isFetchingSettings || isFetchingAutoAnswerChannels} />
            {!autoAnswerChannels || autoAnswerChannels.length === 0 ? (
              <div className={styles.emptyStateWrapper}>
                <EmptyState
                  text='You have not added any channels for auto-answer'
                  clickCallback={() => setShowAddChannelsModal(true)}
                  buttonText='Add channel'
                  hideButton={false}
                />
              </div>
            ) : (
              <>
                <div className={styles.tableWrapper}>
                  <FilterSection
                    setShowAddChannelsModal={setShowAddChannelsModal}
                    setChannelFilter={setChannelFilter}
                    setSearch={setSearch}
                    channelFilter={channelFilter}
                    search={search}
                    paginationOnChange={onChange}
                    channel={channel}
                  />
                  <ChannelsTable
                    handleAutoAnswerForChannels={handleAutoAnswerForChannels}
                    setSelectedForDisable={setSelectedForDisable}
                    channelsToDisplay={channelsToDisplay}
                    pageChannels={pageChannels}
                    selectedForDisable={selectedForDisable}
                    someSelected={someSelected}
                    allSelected={allSelected}
                  />
                </div>
                <Flex
                  justify={hasPagination ? 'space-between' : 'flex-end'}
                  align='center'
                  mt={20}
                  pb={16}
                >
                  {hasPagination && (
                    <Pagination
                      total={totalPages}
                      size='xs'
                      value={pagination.active}
                      onChange={pagination.setPage}
                      color='dark'
                      siblings={2}
                    />
                  )}
                  <div className={styles.pageCounter}>
                    <strong>{pageChannels.length}</strong> of{' '}
                    <strong>{autoAnswerChannels.length}</strong> channels
                  </div>
                </Flex>
              </>
            )}
          </>
        )}
      </div>
      {selectedForDisable.length > 0 && (
        <MultiSelectConfirmationBar>
          <Flex justify='space-between' align='center' style={{ width: '100%' }}>
            <Group justify='left' gap='xs' className={styles.multiSelectConfirmBarLeftSection}>
              <div className={styles.confirmationFooterText}>
                {pluralize('channel', selectedForDisable.length, true)} selected
              </div>
              <div className={styles.resetSelection} onClick={() => setSelectedForDisable([])}>
                Reset
              </div>
            </Group>
            <Button
              disabled={inProgress}
              className={styles.multiSelectDisableButton}
              radius='sm'
              onClick={async () => {
                await handleAutoAnswerForChannels(
                  autoAnswerChannels.filter((c) => selectedForDisable.includes(c.id)),
                  false
                );
              }}
            >
              Disable auto-answer
            </Button>
          </Flex>
        </MultiSelectConfirmationBar>
      )}
      <DeleteWarningModal
        onClose={() => setShowDisableModal(false)}
        handleCancel={() => setShowDisableModal(false)}
        open={showDisableModal}
        handleDelete={async () => {
          await handleSettingsChanged({ enabled: false });
          setSelectedForDisable([]);
          setShowDisableModal(false);
        }}
        removeText='Disable'
        header='Disable auto-answer'
        title={
          <div className={styles.disableModalMessageText}>
            Are you sure you want to disable auto-answer for{' '}
            <strong>{pluralize('channel', autoAnswerChannels.length ?? 0, true)}</strong>
          </div>
        }
        disabled={inProgress}
      >
        <div className={styles.disableModalNoteText}>
          <span>Note:</span> when you disable this,Chai will not proactively participate in
          conversations and answer automatically.
        </div>
      </DeleteWarningModal>
      <AddChannelsModal
        loading={inProgress}
        onClose={() => setShowAddChannelsModal(false)}
        opened={showAddChannelsModal}
        autoAnswerEnabledChannels={autoAnswerChannels.map((c) => c.id) ?? []}
        onConfirm={async (channels) => {
          await handleAutoAnswerForChannels(channels, true);
          setShowAddChannelsModal(false);
        }}
      />
    </>
  );
};

export default AutoAnswer;
