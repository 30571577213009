import { Flex, Text, Divider, Badge, Title } from '@mantine/core';
import PropTypes from 'prop-types';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';
import styles from './styles.module.scss';

const DataPoints = (props) => {
  const { data, connectorName, connectorTitle, connectorCategory, isDescriptionsTab } = props;

  const categories = Object.keys(data ?? {});

  if (!categories.length) {
    return null;
  }

  if (isDescriptionsTab) {
    const descriptionData = data[connectorCategory] || {};

    return (
      <Flex direction='column'>
        <div className={styles.descriptionsTitle}>
          <Badge
            size='xs'
            variant='gradient'
            gradient={{
              from: '#008FD0 4.3%',
              to: '#6129CF 121.81%',
              deg: 85
            }}
            classNames={{ label: styles.badgeLabel }}
          >
            New
          </Badge>
          <Title c='grayscale.8' size={18} fw={600} lh={1.3}>
            Now we support actions on the connections&nbsp;
            <span className={styles.titleSpan}>(Only on slack)</span>
          </Title>
        </div>

        {descriptionData?.description && (
          <Flex direction='column' className={styles.container}>
            <Title size={14} fw={600} lh={1.5} c='grayscale.8' mb={8}>
              {descriptionData?.description}
            </Title>
            {descriptionData?.actions?.map((action, index) => (
              <Text key={index} className={styles.listItems}>
                {index + 1}.&nbsp;{action}
              </Text>
            ))}
          </Flex>
        )}

        <Flex direction='column' className={styles.container}>
          <Title size={14} fw={600} lh={1.5} c='grayscale.8' mb={8}>
            {data?.Usage?.description}
          </Title>
          {data?.Usage?.actions?.map((step, index) => (
            <Text key={index} className={styles.listItems}>
              {index + 1}.&nbsp;{step}
            </Text>
          ))}
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex direction='column' className={styles.container}>
      {connectorName === 'google' ? (
        <>
          {categories.map((cat) => {
            return (
              <Flex key={cat} direction='column'>
                <Text fw={500} mb={12} mt={10}>
                  {cat}
                </Text>
                <Flex gap={16} direction='row' wrap='wrap'>
                  {data[cat].map((point, index) => (
                    <Flex align='center' key={`${cat}-${index}`} gap={8}>
                      {point.icon === 'cross' ? <CrossIcon /> : <TickIcon />}
                      <Text className={styles.dataPoint}>{point.text || point}</Text>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            );
          })}
        </>
      ) : (
        <>
          <Text fw={800} mb={24}>
            Chai absorbs the following information from your {connectorTitle} account.
          </Text>
          {categories.map((cat, index, array) => {
            return (
              <Flex key={cat} direction='column'>
                <Text className={styles.title}>{cat}</Text>
                <Flex gap={16} direction='row' wrap='wrap'>
                  {data[cat].map((point) => (
                    <Flex align='center' key={point} gap={6}>
                      <TickIcon />
                      <Text className={styles.dataPoint}>{point}</Text>
                    </Flex>
                  ))}
                  {/**Hide divider for last category */}
                  {index !== array.length - 1 && <Divider className={styles.divider} size='sm' />}
                </Flex>
              </Flex>
            );
          })}
        </>
      )}
    </Flex>
  );
};

DataPoints.propTypes = {
  data: PropTypes.object,
  connectorTitle: PropTypes.string,
  connectorName: PropTypes.string,
  isDescriptionsTab: PropTypes.bool,
  connectorCategory: PropTypes.string
};

DataPoints.defaultProps = {
  data: {},
  connectorTitle: '',
  connectorName: '',
  isDescriptionsTab: false,
  connectorCategory: ''
};

export default DataPoints;
