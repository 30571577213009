import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useDeleteCompanyMutation,
  useGetCompanyDetailsQuery,
  useUpdateCompanyDetailsMutation
} from 'redux/services/settings';
import resetCache from 'redux/actions/resetStore';
import View from './View';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import localStorage from 'utils/localStorage';
import { NAVBAR_ROUTES } from 'utils/constants';
import { validateFileUpload } from 'utils/helpers';

const General = () => {
  const { user } = useSelector((state) => state.user);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [teamNameValue, setTeamNameValue] = useState('');
  const [showEdit, setShowEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const { data } = useGetCompanyDetailsQuery();
  const [updateCompanyDetails, { isLoading }] = useUpdateCompanyDetailsMutation();
  const [deleteCompany] = useDeleteCompanyMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.name) {
      setTeamNameValue(data.name);
    }
  }, [data?.name]);

  const handleCompanyDeletion = async () => {
    const { data } = await deleteCompany();
    if (data.ok) {
      localStorage.clearStorage();
      dispatch(resetCache);
      navigate(NAVBAR_ROUTES.AUTH.SIGNUP);
    }
  };

  const handleEdit = async () => {
    const response = await updateCompanyDetails({ name: teamNameValue });
    if (response?.data?.ok) {
      toast.success(response.data.message);
      setShowEdit(false);
    }
    setIsDisabled(true);
  };

  const handleDpSave = async (logo) => {
    const validateImage = validateFileUpload(logo);
    if (!validateImage) {
      return;
    }
    const formData = new FormData();
    formData.append('logo', logo);
    const response = await updateCompanyDetails(formData);
    if (response?.data?.ok) {
      toast.success('Photo updated successfully');
    }
  };

  function handleOnChange(updatedTeamName) {
    setTeamNameValue(updatedTeamName);

    if (
      updatedTeamName === user.team.name ||
      !updatedTeamName.length ||
      updatedTeamName.length > 30
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }

  return (
    <>
      {showDeleteWarning && (
        <DeleteWarningModal
          open={true}
          onClose={() => {
            setShowDeleteWarning(false);
          }}
          header='Delete company'
          title='Are you sure you want to delete this company?'
          handleCancel={() => {
            setShowDeleteWarning(false);
          }}
          handleDelete={() => {
            handleCompanyDeletion();
            setShowDeleteWarning(false);
          }}
          body={
            'Note: when you delete this company, all your data including teams, wikis and account data will be deleted from  Chat Aid . People invited to this company will also lose access to Chat Aid here.'
          }
        />
      )}
      <View
        handleDeleteCtaClick={() => setShowDeleteWarning(true)}
        teamDp={user?.team?.photo}
        teamNameValue={teamNameValue}
        handleDpSave={handleDpSave}
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        handleCancel={() => {
          setShowEdit(false);
          setTeamNameValue(data?.name);
        }}
        isLoading={isLoading}
        handleSaveEdit={handleEdit}
        handleOnChange={handleOnChange}
        isDisabled={isDisabled}
      />
    </>
  );
};

export default General;
