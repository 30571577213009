import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Flex, Title, Text, PasswordInput, Button } from '@mantine/core';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import { useGenerateApiKeyMutation } from 'redux/services/automations';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as ShowPassword } from 'assets/icons/show-pass.svg';
import { ReactComponent as HidePassword } from 'assets/icons/hide-pass.svg';
import { ReactComponent as KeyRegenerationIcon } from 'assets/automations/api-regen-confirmation.svg';
import styles from './styles.module.scss';

const ApiGeneration = () => {
  const { user } = useSelector((state) => state.user);
  const [isKeyGenerated, setIsKeyGenerated] = useState(user?.integrationsApiKey || false);
  const [isVisible, setIsVisible] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [generatedKey, setGeneratedKey] = useState(user?.integrationsApiKey || null);
  const [generateApiKey, { isLoading }] = useGenerateApiKeyMutation();

  async function handleCopyUrl() {
    try {
      await navigator.clipboard.writeText(generatedKey);
      toast.success('API key copied successfully');
    } catch (err) {
      toast.error("Couldn't copy API key. Try again");
    }
  }

  function handleModalConfirm() {
    handleGenerateKey();
    setShowConfirmationModal(false);
  }

  async function handleGenerateKey() {
    try {
      const response = await generateApiKey();
      setIsKeyGenerated(true);
      setGeneratedKey(response?.data?.data);
      if (isKeyGenerated) {
        toast.success('New API key generated successfully');
      }
    } catch (error) {
      toast.error('Something went wrong.');
    }
  }

  function modalBody() {
    return (
      <Flex direction='column' align='center' gap='9px'>
        <KeyRegenerationIcon />
        <Text mt={20} className={styles.modalText}>
          When you generate a new API key, Chat Aid will no longer accept your previous API key.
        </Text>
        <Text className={styles.modalText}>
          This means you will have to update your API key on all platforms integrated withChat
          Aidfor automations.
        </Text>
      </Flex>
    );
  }

  return (
    <>
      <Flex direction='column' justify='start' align='start' className={styles.sectionWrapper}>
        <Title className={styles.title}>API key</Title>
        <Text className={styles.description}>
          To trigger actions on Chai, use the API key on supported apps.
        </Text>
        {isKeyGenerated && (
          <div className={styles.apiKeyContainer}>
            <PasswordInput
              variant='default'
              label='API key'
              value={generatedKey}
              disabled
              visible={isVisible}
              classNames={{ input: styles.input, innerInput: styles.innerInput }}
            />
            <Button onClick={() => setIsVisible(!isVisible)} className={styles.visibilityIcon}>
              {isVisible ? <ShowPassword /> : <HidePassword />}
            </Button>
            <Button className={styles.copyBtn} onClick={handleCopyUrl} disabled={isLoading}>
              <CopyIcon />
              &nbsp;Copy
            </Button>
          </div>
        )}

        <Button
          onClick={isKeyGenerated ? () => setShowConfirmationModal(true) : handleGenerateKey}
          className={isKeyGenerated ? styles.generateNewBtn : styles.generateBtn}
          loading={isLoading}
        >
          {isKeyGenerated ? 'Generate new API key' : 'Generate API key'}
        </Button>
      </Flex>

      {showConfirmationModal && (
        <DeleteWarningModal
          open={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
          header='Are you sure you want to generate a new API key?'
          title=''
          body={modalBody()}
          handleCancel={() => setShowConfirmationModal(false)}
          handleDelete={handleModalConfirm}
          removeText='Confirm'
          customClass={styles}
        />
      )}
    </>
  );
};

export default ApiGeneration;
