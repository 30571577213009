import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMediaQuery } from '@mantine/hooks';
import { Divider, Text } from '@mantine/core';
import authenticate from '@truto/truto-link-sdk';
import ConnectionsHeader from '../Components/ConnectionsHeader';
import ConnectionsCatergoryList from '../Components/ConnectionsCategoryList';
import ConnectionsList from '../Components/ConnectionsList';
import ConnectorDetailsModal from '../Components/ConnectorDetailsModal';
import NoConnectionFound from '../Components/EmptyState/NoConnectionFound';
import NoSearchResult from '../Components/EmptyState/NoSearchResult';
import RequestModal from '../../../components/Modals/RequestModal';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import SlackConnectModal from 'components/Modals/SlackConnectModal';
import AlreadyConnectedModal from 'components/Modals/AlreadyConnectedModal';
import { usePostSlackConnectMutation } from 'redux/services/slack-connect';
import { useDisconnectProviderMutation } from 'redux/services/integrations';
import userApi, { useLazyGetUserQuery } from 'redux/services/user';
import { useLazyGetTrutoLinkTokenQuery } from 'redux/services/truto';
import { useEnableConnectionMutation } from 'redux/services/connectors';
import { providerTitle } from 'pages/Integrations/utils';
import { NAVBAR_ROUTES } from 'utils/constants';
import { constructConnectionsByCategories } from '../utils';
import { CATEGROY_TAB, CONNECTOR_CATEGORIES } from '../constants';
import { handleApiError } from 'utils/helpers';
import styles from './styles.module.scss';

const Connections = () => {
  const searchParams = new URLSearchParams(location.search);
  const { user } = useSelector((state) => state.user);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCategory, setActiveCategory] = useState(CATEGROY_TAB.MY_CONNECTIONS);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showDisconnectModal, setDisconnectModal] = useState({
    state: false,
    connector: null,
    disabled: false
  });
  const [showDisableModal, setDisableModal] = useState({
    state: false,
    connector: null,
    disabled: false
  });
  const [slackConnectModal, setSlackConnectModal] = useState(false);
  const [alreadyConnectedModal, setAlreadyConnectedModal] = useState(false);
  const isMobileScreen = useMediaQuery('(max-width:480px)');
  const navigate = useNavigate();
  const [postSlackConnect, { isSuccess: slackConnectSuccess }] = usePostSlackConnectMutation();
  const [disconnectProvider] = useDisconnectProviderMutation();
  const [getTrutoLinkTokenTrigger] = useLazyGetTrutoLinkTokenQuery();
  const [enableConnection] = useEnableConnectionMutation();
  const { connections } = useSelector((state) => state.connections);
  const dispatch = useDispatch();
  const [selectedConnector, setSelectedConnector] = useState('');
  const isInstalled = searchParams.get('ok');
  const success = searchParams.get('success');
  const provider = searchParams.get('provider');
  const [getUser] = useLazyGetUserQuery();
  const [retryCount, setRetryCount] = useState(0);
  const connectorFromParams = searchParams.get('connector') ?? provider;
  const conn = connections?.find((conn) => conn.name === connectorFromParams);
  const [selectedConnection, setSelectedConnection] = useState(conn);

  useEffect(() => {
    if (success) {
      toast.success(`Started connecting to ${providerTitle(provider)}`);
      window.history.pushState(null, null, window.location.pathname);
    }
  }, []);

  useEffect(() => {
    if (connectorFromParams === 'slack') {
      const conn = connections?.find((conn) => conn.name === connectorFromParams);
      setSelectedConnection(conn);
    }

    if (isInstalled === 'true') {
      toast.success('Installed on Slack successfully.');
    } else if (isInstalled === 'false') {
      toast.error('Failed to install on Slack.');
    }
  }, [isInstalled]);

  async function handlePostConnect(connectionName) {
    try {
      const result = await getUser();
      // Check if the connection already exists
      if (user?.connections?.some((conn) => conn.provider === connectionName)) {
        // If the connection already exists, return early
        return;
      }

      // Initialize or update the connectionInitiated object in local storage
      const connectionInitiated = {
        ...(JSON.parse(localStorage.getItem('connectionInitiated')) || {}),
        [connectionName]: true
      };
      localStorage.setItem('connectionInitiated', JSON.stringify(connectionInitiated));

      if (result?.data?.user?.connections) {
        // Remove successfully connected provider from connectionInitiated
        result.data.user.connections.forEach(({ provider }) => {
          delete connectionInitiated[provider];
        });

        // Check if there are pending connections
        if (Object.keys(connectionInitiated).length === 0) {
          // If no pending connections, remove the key from local storage
          localStorage.removeItem('connectionInitiated');
        } else {
          // Update the connectionInitiated in local storage
          localStorage.setItem('connectionInitiated', JSON.stringify(connectionInitiated));

          // Calculate the backoff interval for the next attempt
          const backoffInterval = Math.pow(2, retryCount) * 1000;

          setRetryCount(retryCount + 1);

          // Schedule the next attempt with exponential backoff
          setTimeout(() => handlePostConnect(connectionName), backoffInterval);
        }
      } else {
        // If user data is not available, remove the provider from local storage
        localStorage.removeItem('provider');
      }
    } catch (error) {
      handleApiError(error);
    }
  }

  let connectorsByCategories = constructConnectionsByCategories(connections);

  if (searchTerm) {
    connectorsByCategories = [
      {
        name: 'Search result',
        connectors: connections.filter((conn) =>
          conn.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      }
    ];
  } else if (activeCategory === CATEGROY_TAB.MY_CONNECTIONS) {
    connectorsByCategories = [
      {
        name: 'My connections',
        connectors: connections.filter((conn) => conn.isConnected)
      }
    ];
  }

  if (isMobileScreen && activeCategory !== CATEGROY_TAB.MY_CONNECTIONS) {
    connectorsByCategories = connectorsByCategories.filter((cat) => cat.name === activeCategory);
  }

  const handleEnable = async (conn) => {
    try {
      setSelectedConnector(conn.name);
      const response = await enableConnection(conn.name);
      if (response.data.ok) {
        setSelectedConnector('');
        dispatch(userApi.util.invalidateTags(['User']));
        toast.success(`${conn.title} enabled successfully`);
      }
    } catch (error) {
      setSelectedConnector('');
    }
  };

  const handleAddSource = () => {
    navigate(NAVBAR_ROUTES.INTEGRATIONS);
  };

  const handleConnect = async (conn) => {
    setSelectedConnector(conn.name);
    try {
      const options = {
        integration: conn.name,
        noBack: true,
        authFormat: 'oauth2',
        skipRapidForm: true,
        iframe: true
      };

      const params = { provider: conn.name, isConnect: true };
      const { data } = await getTrutoLinkTokenTrigger(params);
      setSelectedConnection(null);
      const response = await authenticate(data.linkToken, options);
      if (response?.result === 'success') {
        setSelectedConnector('');
        handlePostConnect(conn.name);
        toast.success(`${conn.title} connected successfully`);
        setSelectedConnection(conn);
      }
    } catch (error) {
      setSelectedConnector('');
      if (
        error.message === 'Not Found: Error while fetching resource - LIST ticketing/workspaces'
      ) {
        return toast.error('Unable to find the workspace by this name. Please try again.');
      }
      return toast.error(
        'Authentication error: Unable to proceed. Please check your credentials or try again.'
      );
    }
  };

  const handleDisconnect = async () => {
    setDisconnectModal({ ...showDisconnectModal, disabled: true });
    try {
      const response = await disconnectProvider(showDisconnectModal.connector.name);
      if (response.data.ok) {
        toast.success(`${showDisconnectModal.connector.title} disconnected successfully`);
        setDisconnectModal({
          state: false,
          connector: null,
          disabled: false
        });
      }
    } catch (error) {
      toast.error('Unable to disconnect, Try again!');
    }
  };

  const handleDisable = async () => {
    setDisableModal({ ...showDisableModal, disabled: true });
    try {
      const response = await disconnectProvider(showDisableModal.connector.name);
      if (response.data.ok) {
        toast.success(`${showDisableModal.connector.title} disabled successfully`);
        setDisableModal({
          state: false,
          connector: null,
          disabled: false
        });
      }
    } catch (error) {
      toast.error('Unable to disable, Try again!');
    }
  };

  const handleBookCall = () => {
    window.open('https://calendly.com/chataid/25min', '_blank');
    setShowRequestModal(false);
  };

  const handleChatOnSlack = async () => {
    const response = await postSlackConnect({
      emails: [user?.email]
    });
    setShowRequestModal(null);
    if (response?.data?.channelId) {
      setAlreadyConnectedModal(true);
    } else {
      if (response?.data.ok) {
        setSlackConnectModal(true);
      }
    }
  };

  const handleCloseDetailsModal = () => {
    setSelectedConnection(null);
    if (connectorFromParams) {
      navigate(NAVBAR_ROUTES.SETTINGS_TABS.CONNECTIONS);
    }
  };

  return (
    <div className={styles.wrapper}>
      {slackConnectSuccess && (
        <SlackConnectModal opened={slackConnectModal} setOpened={setSlackConnectModal} />
      )}
      {selectedConnection && (
        <ConnectorDetailsModal
          conn={connections?.find((conn) => conn.name === selectedConnection.name) ?? {}}
          onClose={handleCloseDetailsModal}
          handleConnect={handleConnect}
          handleDisable={(conn) =>
            setDisableModal({
              state: true,
              connector: conn,
              disabled: false
            })
          }
          handleEnable={handleEnable}
          handleDisconnect={(conn) =>
            setDisconnectModal({
              state: true,
              connector: conn,
              disabled: false
            })
          }
          handleRequest={() => setShowRequestModal(true)}
          selectedConnector={selectedConnector}
          handleAddSource={handleAddSource}
        />
      )}

      {alreadyConnectedModal && (
        <AlreadyConnectedModal
          opened={alreadyConnectedModal}
          setOpened={setAlreadyConnectedModal}
        />
      )}
      {showRequestModal && (
        <RequestModal
          setShowRequestModal={setShowRequestModal}
          handleBookCall={handleBookCall}
          handleChatOnSlack={handleChatOnSlack}
        />
      )}
      {showDisconnectModal.state && (
        <DeleteWarningModal
          open={true}
          header={`Disconnect ${showDisconnectModal?.connector?.title}`}
          title={
            <>
              Are you sure you want to disconnect from&nbsp;
              <strong>{showDisconnectModal?.connector?.title}</strong> ?
            </>
          }
          handleDelete={handleDisconnect}
          handleCancel={() => {
            setDisconnectModal({
              state: false,
              connector: null,
              disabled: false
            });
          }}
          onClose={() => {
            setDisconnectModal({
              state: false,
              connector: null,
              disabled: false
            });
          }}
          removeText='Disconnect'
          disabled={showDisconnectModal.disabled}
        >
          <Text className={styles.text}>
            <span className={styles.fadedText}>Note:&nbsp;</span>When you disconnect, sources you
            added through this connection will be removed.
          </Text>
        </DeleteWarningModal>
      )}
      {showDisableModal.state && (
        <DeleteWarningModal
          open={true}
          header={`Disable ${showDisableModal?.connector?.title}`}
          title={
            <>
              Are you sure you want to disable <strong>{showDisableModal?.connector?.title}</strong>
              &nbsp;connection?
            </>
          }
          handleDelete={handleDisable}
          handleCancel={() => {
            setDisableModal({
              state: false,
              connector: null,
              disabled: false
            });
          }}
          onClose={() => {
            setDisableModal({
              state: false,
              connector: null,
              disabled: false
            });
          }}
          removeText='Disable'
          disabled={showDisableModal.disabled}
        >
          <Text className={styles.text}>
            <span className={styles.fadedText}>Note:&nbsp;</span>
            When you disable, sources you added through this connection will be removed.
          </Text>
        </DeleteWarningModal>
      )}
      <ConnectionsHeader
        value={searchTerm}
        onChange={(v) => setSearchTerm(v)}
        CTAClickHandler={() => navigate(NAVBAR_ROUTES.INTEGRATIONS)}
      />
      <Divider />
      <div className={styles.body}>
        {!searchTerm && (
          <ConnectionsCatergoryList
            activeCategory={activeCategory}
            handleCategoryChange={(cat) => {
              setActiveCategory(cat);
              navigate(NAVBAR_ROUTES.SETTINGS_TABS.CONNECTIONS + '#' + cat, { replace: true });
            }}
            connectedCount={connections.filter((conn) => conn.isConnected).length}
            categories={CONNECTOR_CATEGORIES}
          />
        )}
        {connectorsByCategories[0]?.connectors.length ? (
          <ConnectionsList
            setSelectedConnection={setSelectedConnection}
            connectorsByCategories={connectorsByCategories}
            setActiveCategory={setActiveCategory}
            hideChevron={
              !!searchTerm || !!activeCategory === CATEGROY_TAB.MY_CONNECTIONS || !!isMobileScreen
            }
            searchTerm={searchTerm}
          />
        ) : (
          <>
            {activeCategory === CATEGROY_TAB.MY_CONNECTIONS && !searchTerm && (
              <NoConnectionFound
                CTAHandler={() => setActiveCategory(CONNECTOR_CATEGORIES[0].name)}
              />
            )}
            {searchTerm && <NoSearchResult />}
          </>
        )}
      </div>
    </div>
  );
};

export default Connections;
