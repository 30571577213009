import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Modal,
  Title,
  Paper,
  Text,
  TextInput,
  ScrollArea,
  Checkbox,
  Button,
  Skeleton,
  Tooltip
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { maybePluralize } from 'utils/utilities';
import styles from './styles.module.scss';

const View = (props) => {
  const {
    opened,
    setOpened,
    setSelectedUsers,
    selectedUsers,
    setFilteredUsers,
    filteredUsers,
    isRequestLoading,
    setIsApproveModalOpen,
    requestedUsers,
    setIsModalOpen,
    unassignedLicenses,
    setIsDenyConfirmOpen
  } = props;

  // Search Term
  const [searchTerm, setSearchTerm] = useState('');

  const screen767 = useMediaQuery('(max-width: 767px)');

  // User Select or Deselect
  const handleUserSelection = (e) => {
    if (e.target.checked) {
      setSelectedUsers((userList) => [...userList, e.target.value]);
    } else {
      setSelectedUsers(selectedUsers.filter((el) => el !== e.target.value));
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (!e.target.value) {
      setFilteredUsers(requestedUsers);
      return;
    }
    setFilteredUsers(
      requestedUsers.filter((user) => {
        return user.name.toLowerCase().includes(searchTerm.toLowerCase());
      })
    );
  };

  const handleAssignLicense = () => {
    if (unassignedLicenses - selectedUsers.length > 0) {
      setIsApproveModalOpen(true);
      return;
    }
    if (unassignedLicenses - selectedUsers.length <= 0) {
      setIsModalOpen(true);
      return;
    }
  };

  const loadingBars = (count = 10) => {
    return Array.from({ length: count }, (_, i) => (
      <Skeleton mb={10} key={`Skeleton_${i}`} radius={2} height={20} />
    ));
  };

  return (
    <Modal
      opened={opened}
      size={580}
      radius={4}
      centered
      onClose={() => setOpened(false)}
      padding={24}
      className='mobile-modal'
      title={
        <Text className={styles.modalTitle} inherit>
          Assign Licenses
        </Text>
      }
    >
      <div className={styles.modalBody}>
        <Flex className={styles.licenseStats}>
          <Paper shadow='none' mr={120}>
            <Text className={styles.statLabel}>Licenses requested</Text>
            <Title className={styles.statCount} order={6}>
              {requestedUsers.length}
            </Title>
          </Paper>

          <Paper shadow='none'>
            <Text className={styles.statLabel}>
              Unassigned Licenses&nbsp;
              <Tooltip
                label='Assign these Licenses to teammates requesting access.'
                multiline={false}
              >
                <span>
                  <SVGIcon name={ICONS_LIST.TOOL_TIP} height={14} width={14} />
                </span>
              </Tooltip>
            </Text>
            <Title className={styles.statCount} order={6}>
              {unassignedLicenses}
            </Title>
          </Paper>
        </Flex>

        <TextInput
          className={styles.searchInput}
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder='Search by name'
          label='These teammates have requested Licenses to access  Chat Aid :'
          leftSection={<SearchIcon />}
        />
        <ScrollArea className={styles.requestsContainer} type='auto' scrollbarSize={6}>
          {isRequestLoading && loadingBars()}
          {filteredUsers.map((user) => (
            <div className={styles.userItem} key={user._id}>
              <Checkbox
                color='dark'
                checked={selectedUsers.includes(user._id) || selectedUsers.includes(user.userId)}
                value={user.userId}
                radius={4}
                mr={10}
                onChange={handleUserSelection}
              />
              <Text className={styles.userName}>{user.name}</Text>
            </div>
          ))}
        </ScrollArea>
        <Flex mt={20} justify='space-between' align='center'>
          {!screen767 && (
            <Text className={styles.selectedUserText}>
              {selectedUsers.length} {maybePluralize(selectedUsers.length, 'user')} selected
            </Text>
          )}

          <div className={styles.mobileModalFooter}>
            <Button
              loading={isRequestLoading}
              className={styles.primaryBtnOutline}
              onClick={() => {
                setIsDenyConfirmOpen(true);
              }}
            >
              Deny {maybePluralize(selectedUsers.length, 'request')}
            </Button>
            <Button
              ml={20}
              loading={isRequestLoading}
              className={styles.primaryBtn}
              onClick={handleAssignLicense}
            >
              Assign {maybePluralize(selectedUsers.length, 'License')}
            </Button>
          </div>
        </Flex>
      </div>
    </Modal>
  );
};

View.propTypes = {
  opened: PropTypes.bool.isRequired,
  setOpened: PropTypes.func.isRequired,
  setSelectedUsers: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  isRequestLoading: PropTypes.bool.isRequired,
  requestedUsers: PropTypes.array.isRequired,
  unassignedLicenses: PropTypes.number.isRequired,
  setIsDenyConfirmOpen: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  setIsApproveModalOpen: PropTypes.func.isRequired,
  filteredUsers: PropTypes.array.isRequired,
  setFilteredUsers: PropTypes.func.isRequired
};

export default View;
