import { useEffect, useState, useMemo } from 'react';
import { Flex, Button, Text } from '@mantine/core';
import Layout from 'components/Layout';
import TeamFilter from 'pages/Integrations/TeamFilter/index';
import Reports from './Reports/index';
import { useGetTeamsQuery } from 'redux/services/settings';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from './styles.module.scss';

const SurveyReports = () => {
  const [selectedTeam, setSelectedTeam] = useState();
  const [report, setReport] = useState(null);
  const { data: { teams = [] } = {}, isFetching } = useGetTeamsQuery();

  const options = useMemo(() => {
    if (isFetching) return [];
    return teams
      .filter((team) => team?.textTableExtraction)
      .map((team) => ({
        value: team.teamId,
        label: team.name,
        companywiki: team.companyWiki
      }));
  }, [teams, isFetching]);

  const selectedTeamName = useMemo(() => {
    return options.find((option) => option.value === selectedTeam)?.label || '';
  }, [options, selectedTeam]);

  useEffect(() => {
    if (!selectedTeam && options.length > 0) {
      setSelectedTeam(options[0].value);
    }
  }, [options, selectedTeam]);

  const handleFilterChange = (value) => {
    setSelectedTeam(value);
  };

  let pageTitle = 'Survey reports';
  if (selectedTeam && report?.name) {
    pageTitle = (
      <Flex align='center'>
        <Button
          className={styles.navigateBackButton}
          onClick={() => {
            setReport(null);
          }}
          variant='default'
        >
          <SVGIcon name={ICONS_LIST.LEFT_ARROW} height={18} width={18} />
        </Button>

        <Text className={styles.providerTitle}>
          Survey reports &gt; {selectedTeamName} &gt; <span>{report?.name}</span>
        </Text>
      </Flex>
    );
  }

  return (
    <Layout pageTitle={pageTitle}>
      <div className={styles.content}>
        <div className={styles.filter}>
          <TeamFilter
            hideSearch
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
            options={options}
            handleChange={handleFilterChange}
            disabled={isFetching}
          />
        </div>

        {selectedTeam && <Reports teamId={selectedTeam} report={report} setReport={setReport} />}
      </div>
    </Layout>
  );
};

export default SurveyReports;
