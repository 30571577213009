import { ReactComponent as ErrorIcon } from 'assets/icons/error_icon.svg';

export const TABS = {
  appearance: { id: 1, value: 'appearance', label: 'Appearance' },
  search: { id: 2, value: 'search', label: 'Search', icon: <ErrorIcon /> },
  wiki: { id: 3, value: 'wiki', label: 'Wiki', icon: <ErrorIcon /> },
  answer: { id: 4, value: 'answer', label: 'Answer' },
  installation: { id: 5, value: 'installation', label: 'Installation' }
};

export const POSITIONS = {
  BOTTOM_RIGHT: { label: 'Bottom right', value: 'bottom-right' },
  BOTTOM_LEFT: { label: 'Bottom left', value: 'bottom-left' },
  TOP_RIGHT: { label: 'Top right', value: 'top-right' },
  TOP_LEFT: { label: 'Top left', value: 'top-left' }
};

export const THEMES = {
  LIGHT: {
    name: 'light',
    botMessageColor: '#000000',
    botMessageBackground: '#F3F3F3',
    userMessageColor: '#FFFFFF',
    userMessageBackground: '#2C71C1',
    inputBackground: '#FCFCFC',
    inputColor: '#959AA8',
    inputBorder: '#E8E8E8',
    chatBackground: '#FEFEFF',
    sendIconColor: '#2C71C1',
    brandColor: '#2C71C1',
    widgetLauncherBackground: '#185EB0'
  },
  DARK: {
    name: 'dark',
    botMessageColor: '#FFFFFF',
    botMessageBackground: '#676395',
    userMessageColor: '#FFFFFF',
    userMessageBackground: '#706DFF',
    inputBackground: '#413C67',
    inputColor: '#959AA8',
    inputBorder: '#5F5C7B',
    chatBackground: '#2C3267',
    sendIconColor: '#FFFFFF',
    brandColor: '#323977',
    widgetLauncherBackground: '#185EB0'
  },
  CUSTOM: {
    name: 'custom',
    botMessageColor: '#000000',
    botMessageBackground: '#F5F5F5',
    userMessageColor: '#FFFFFF',
    userMessageBackground: '#D35108',
    inputBackground: '#FFFFFF',
    inputColor: '#B1B1B1',
    inputBorder: '#E8E2E2',
    chatBackground: '#FFFFFF',
    sendIconColor: '#D8601D',
    brandColor: '#D35108',
    widgetLauncherBackground: '#185EB0'
  }
};

export const COLOR_INPUTS = [
  { id: 1, label: 'Bot message text color', value: 'botMessageColor' },
  {
    id: 2,
    label: 'Bot message background color',
    value: 'botMessageBackground'
  },
  {
    id: 3,
    label: 'User message text color',
    value: 'userMessageColor'
  },
  {
    id: 4,
    label: 'User message background color',
    value: 'userMessageBackground'
  },
  {
    id: 5,
    label: 'Input background color',
    value: 'inputBackground'
  },
  {
    id: 6,
    label: 'Input text color',
    value: 'inputColor'
  },
  {
    id: 7,
    label: 'Input border color',
    value: 'inputBorder'
  },
  {
    id: 8,
    label: 'Chat background color',
    value: 'chatBackground'
  },
  {
    id: 9,
    label: 'Send icon color',
    value: 'sendIconColor'
  },
  {
    id: 10,
    label: 'Brand color',
    value: 'brandColor'
  },
  {
    id: 11,
    label: 'Widget launcher',
    value: 'widgetLauncherBackground'
  }
];

export const DEFAULT_WIDGET_SETTINGS = {
  name: ' Chat Aid ',
  welcomeMessage: 'Hi there, how can I help you?',
  botMessage: "Ask me a question on your wiki and I'll search...",
  position: 'bottom-right',
  width: 360,
  height: 600,
  theme: THEMES.LIGHT,
  search: {
    ai: false,
    training: true
  },
  allowedOrigins: [],
  wikis: [],
  whiteLabel: false,
  persona: ''
};
