import { Anchor } from '@mantine/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as LauncherIcon } from 'assets/widgets/launcher.svg';
import { ReactComponent as SendIcon } from 'assets/widgets/send-icon.svg';
import { ReactComponent as ChaiLogo } from 'assets/ask-chai/chai-profile.svg';
import { THEMES } from '../data.jsx';
import styles from './styles.module.scss';

const SmallPreview = (props) => {
  const {
    name,
    welcomeMessage,
    botMessage,
    position,
    width,
    height,
    theme,
    inModal,
    show,
    showLauncher,
    whiteLabel
  } = props;
  const [y, x] = position.split('-');

  return (
    <div
      id='widget-preview'
      className={classnames(styles.container, { [styles.inModal]: inModal, [styles.show]: show })}
      style={{
        flexDirection: y === 'top' ? 'column-reverse' : 'column'
      }}
    >
      <div
        className={styles.chatBox}
        style={{
          width: `${width}px`,
          height: `${height}px`,
          backgroundColor: theme.chatBackground
        }}
      >
        <div className={styles.header} style={{ backgroundColor: theme.brandColor }}>
          <div className={styles.name}>{name}</div>
          <div className={styles.welcomeMessage}>{welcomeMessage}</div>
        </div>
        <div className={styles.messages}>
          <div
            className={styles.botMessage}
            style={{
              backgroundColor: theme.botMessageBackground,
              color: theme.botMessageColor
            }}
          >
            {botMessage}
          </div>
          <div
            className={styles.userMessage}
            style={{
              backgroundColor: theme.userMessageBackground,
              color: theme.userMessageColor
            }}
          >
            What is the spell to open the Marauder&apos;s Map?
          </div>
        </div>
        <div
          className={classnames(styles.inputWrapper, { [styles.whiteLabeled]: whiteLabel })}
          style={{
            borderColor: theme.inputBorder,
            backgroundColor: theme.inputBackground
          }}
        >
          <div style={{ color: theme.inputColor }}>What is the spell disarm someone? |</div>
          <SendIcon fill={theme.sendIconColor} />
        </div>
        {!whiteLabel && (
          <div className={styles.poweredByAlbus}>
            <span>Powered by&nbsp;</span>
            <span>
              <ChaiLogo />
            </span>
            <Anchor href='https://www.chataid.com/' target='_blank'>
              Chat Aid
            </Anchor>
          </div>
        )}
      </div>
      {showLauncher && (
        <div
          className={styles.launcher}
          style={{ alignSelf: x === 'right' ? 'flex-end' : 'flex-start' }}
        >
          <LauncherIcon fill={theme.widgetLauncherBackground} />
        </div>
      )}
    </div>
  );
};

SmallPreview.defaultProps = {
  name: ' Chat Aid ',
  welcomeMessage: 'Hi there, how can I help you?',
  botMessage: "Ask me a question on your wiki and I'll search...",
  position: 'bottom-right',
  width: 360,
  height: 600,
  theme: THEMES.LIGHT,
  showLauncher: true,
  whiteLabel: false
};

SmallPreview.propTypes = {
  name: PropTypes.string,
  welcomeMessage: PropTypes.string,
  botMessage: PropTypes.string,
  position: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  theme: PropTypes.object,
  inModal: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  showLauncher: PropTypes.bool.isRequired,
  whiteLabel: PropTypes.bool
};

export default SmallPreview;
