import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Text, Title } from '@mantine/core';
import userApi from 'redux/services/user';
import FirstConnectionBanner from 'pages/OnBoarding/Components/FirstConnectionBanner/View';
import QuestionEmptyState from 'pages/OnBoarding/Components/QuestionEmptyState';
import { CONNECTOR_LIST } from 'pages/ControlPanel/constants';
import { NAVBAR_ROUTES } from 'utils/constants';
import styles from './styles.module.scss';

const View = (props) => {
  const {
    provider,
    handleSuggestedQuestionClick,
    handleOpenPromptIdeasPage,
    isOnboarding,
    wikiPromptIdeas,
    canGenerateQuestion,
    isFetching,
    isPolling,
    setIsPolling
  } = props;

  const [showBanner, setShowBanner] = useState(isOnboarding);

  const trainingDuration =
    CONNECTOR_LIST.find((conn) => conn.name === provider)?.trainingTime ?? '12 hours';

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const promptIdeasList = [...wikiPromptIdeas.slice(0, 4)];
  useEffect(() => {
    if (!isOnboarding) {
      return;
    }

    if (!isFetching && wikiPromptIdeas.length === 0 && canGenerateQuestion) {
      //if no promptIdeas and canGenerateQuetion is true, start polling
      setIsPolling(true);
    }
    if (wikiPromptIdeas.length) {
      //if recieved promptIdeas, stop polling
      setIsPolling(false);
    }
    if (!canGenerateQuestion && !wikiPromptIdeas.length) {
      //if no promptIdeas and hasNext is false, redirect to dashboard
      dispatch(userApi.util.invalidateTags(['User']));
      navigate(NAVBAR_ROUTES.ASK_CHAI);
      setIsPolling(false);
    }
  }, [wikiPromptIdeas, isFetching]);

  if (isOnboarding && isPolling && !wikiPromptIdeas.length) {
    return <QuestionEmptyState />;
  }

  if (!isFetching && wikiPromptIdeas.length && !isPolling) {
    return (
      <Flex gap={35} className={styles.wrapper} m={!showBanner && 'auto'}>
        {isOnboarding && showBanner && (
          <FirstConnectionBanner
            trainingDuration={trainingDuration}
            setShowBanner={setShowBanner}
          />
        )}
        <Flex gap={24} align='center' direction='column' className={styles.container}>
          <Title size={18}>Here are some suggested questions:</Title>
          <div className={styles.questionsGridContainer}>
            {promptIdeasList.map((question, index) => (
              <div
                key={index}
                onClick={() => handleSuggestedQuestionClick(question)}
                className={styles.questionContainer}
              >
                <Text className={styles.questionText}>{question.question}</Text>
              </div>
            ))}
          </div>
          {!isOnboarding && (
            <Button onClick={handleOpenPromptIdeasPage} className={styles.viewMoreButton}>
              View more questions
            </Button>
          )}
        </Flex>
      </Flex>
    );
  }
};

View.propTypes = {
  provider: PropTypes.string.isRequired,
  handleSuggestedQuestionClick: PropTypes.func.isRequired,
  handleOpenPromptIdeasPage: PropTypes.func.isRequired,
  isOnboarding: PropTypes.bool.isRequired,
  wikiPromptIdeas: PropTypes.array,
  canGenerateQuestion: PropTypes.bool,
  isFetching: PropTypes.bool,
  setIsPolling: PropTypes.func.isRequired,
  isPolling: PropTypes.bool.isRequired
};

View.defaultProps = {
  wikiPromptIdeas: [],
  canGenerateQuestion: false,
  isFetching: false,
  setIsPolling: () => null,
  isPolling: false
};

export default View;
