import PropTypes from 'prop-types';
import { Table, Button } from '@mantine/core';
import { useState } from 'react';
import { Checkbox } from '@mantine/core';
import Tables from './Tables';
import styles from './styles.module.scss';

const View = ({ sourcesList, handleGenerateComparisonDataset, report, setReport }) => {
  const [selectedSurveyReports, setSelectedSurveyReports] = useState([]);

  const handleCheckboxChange = (isChecked, id) => {
    if (isChecked) {
      setSelectedSurveyReports([...selectedSurveyReports, id]);
    } else {
      setSelectedSurveyReports(selectedSurveyReports.filter((item) => item !== id));
    }
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allIds = sourcesList.map((source) => source.id);
      setSelectedSurveyReports(allIds);
    } else {
      setSelectedSurveyReports([]);
    }
  };

  return (
    <div className={styles.container}>
      {!report && (
        <>
          <div className={styles.title}>
            <span className={styles.text}>
              You can manage your survey reports here, edit tables, generate comparison data, and
              add detailed descriptions.
            </span>
            <Button
              className='primaryBtn'
              onClick={() => handleGenerateComparisonDataset(selectedSurveyReports)}
            >
              Generate keypoints
            </Button>
          </div>

          <div className={styles.surveyReportsTableWrapper}>
            <Table className={styles.table} fontSize='md' highlightOnHover>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th className={styles.selectAllCheckbox}>
                    <Checkbox
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => handleSelectAll(e.target.checked)}
                      radius={4}
                      color='#1CA555'
                      checked={selectedSurveyReports.length === sourcesList.length}
                    />
                  </Table.Th>
                  <Table.Th className={styles.header}>Survey reports</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {sourcesList?.map((source, index) => (
                  <Table.Tr key={index} className={styles.rows}>
                    <Table.Td>
                      <Checkbox
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => handleCheckboxChange(e.target.checked, source.id)}
                        radius={4}
                        checked={selectedSurveyReports.includes(source.id)}
                        color='#1CA555'
                      />
                    </Table.Td>
                    <Table.Td onClick={() => setReport(source)}>{source.name}</Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </div>
        </>
      )}
      {report && <Tables report={report} />}
    </div>
  );
};

View.defaultProps = {
  sourcesList: [],
  handleGenerateComparisonDataset: () => {},
  report: null,
  setReport: () => {}
};

View.propTypes = {
  sourcesList: PropTypes.array,
  handleGenerateComparisonDataset: PropTypes.func,
  report: PropTypes.object,
  setReport: PropTypes.func
};

export default View;
