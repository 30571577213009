import { useRouteError } from 'react-router-dom';
import ErrorPage from 'pages/ErrorPage';

const ErrorBoundary = () => {
  const error = useRouteError();

  if (error !== null) {
    return (
      <ErrorPage
        errorStatus={error?.status || 500}
        errorPageTitle={'Oops, something went wrong!'}
        errorDescription={
          "It seems like an unexpected error has occurred. Don't worry, our team is on the case! While we're at it, try refreshing the page!"
        }
      />
    );
  }

  throw error;
};

export default ErrorBoundary;
