import { Paper, Image, Text } from '@mantine/core';
import PropTypes from 'prop-types';
import AddSourceButton from './AddSourceButton';
import EmptyStateLogo from 'assets/empty-state-logo.svg';
import styles from './styles.module.scss';

const EmptyState = (props) => {
  const { isDisabled, clickCallback, text, hideButton, buttonText } = props;

  return (
    <Paper className={styles.paper}>
      <Image src={EmptyStateLogo} className={styles.image} alt='Empty State Logo' />
      <Text className={styles.text}>{text}</Text>
      {hideButton ? null : (
        <AddSourceButton
          isDisabled={isDisabled}
          clickCallback={clickCallback}
          buttonText={buttonText}
        />
      )}
    </Paper>
  );
};

EmptyState.propTypes = {
  isDisabled: PropTypes.bool,
  clickCallback: PropTypes.func,
  text: PropTypes.string,
  hideButton: PropTypes.bool,
  buttonText: PropTypes.string
};

EmptyState.defaultProps = {
  isDisabled: false,
  clickCallback: () => {},
  text: 'Chai is not training on any internal documents. To get started, add a source',
  hideButton: true,
  buttonText: 'Add source'
};

export default EmptyState;
