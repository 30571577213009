import PropTypes from 'prop-types';
import { Button, Flex } from '@mantine/core';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from './styles.module.scss';

const Header = (props) => {
  const { backBtnClickHandeler } = props;

  return (
    <Flex align={'center'}>
      <Button
        className={styles.navigateBackButton}
        onClick={backBtnClickHandeler}
        variant='default'
      >
        <SVGIcon name={ICONS_LIST.LEFT_ARROW} height={18} width={18} />
      </Button>

      <Flex className={styles.pageTitle} align={'center'}>
        Ask Chai&nbsp;&gt; <b>Suggested questions</b>
      </Flex>
    </Flex>
  );
};

Header.propTypes = {
  backBtnClickHandeler: PropTypes.func.isRequired
};

export default Header;
