export const AI = 'ai';
export const DOBBY = 'dobby';

export const CHANNEL_TYPE = {
  PUBLIC: 'public',
  PRIVATE: 'private',
  PERSONAL: 'personal',
  GROUP: 'group',
  WEB: 'web'
};

export const DOCUMENT_TYPE = {
  SLACK_PUBLIC: 'slack_public_channel',
  SLACK_PRIVATE: 'slack_private_channel'
};

export const DATE_DROPDOWN_VALUES = {
  ALL: 'ALL',
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  CUSTOM: 'CUSTOM'
};

export const FEATURE_FLAGS = {
  SLACK_CONNECT: 'slack_connect',
  PERSONAS: 'personas',
  CHROME_EXTENSION: 'chrome_extension',
  WIKI_BANNER: 'wiki_banner',
  GOOGLE_DRIVE_AUTO_SYNC: 'google_drive_auto_sync',
  ACTIONS_INTRODUCTION_MODAL: 'actions_introduction_modal'
};

export const DATE_DROPDOWN_OPTIONS = [
  { value: DATE_DROPDOWN_VALUES.ALL, label: 'All' },
  { value: DATE_DROPDOWN_VALUES.TODAY, label: 'Today' },
  { value: DATE_DROPDOWN_VALUES.YESTERDAY, label: 'Yesterday' },
  { value: DATE_DROPDOWN_VALUES.CUSTOM, label: 'Custom range' }
];

// Team Questions Training Source Filters
export const TRAINING_FILTERS_OPTIONS = [
  { value: '', label: 'All' },
  { value: 'training', label: 'Answered from training source' },
  { value: 'ai', label: 'Answered by AI' }
];

export const CATEGORIES_LIST = [
  { label: 'All', value: '' },
  { label: 'Work', value: 'work' },
  { label: 'Technology', value: 'technology' },
  { label: 'Miscellaneous', value: 'miscellaneous' }
];
// License Management User Status Filters
export const LICENSE_STATUS_LIST = [
  { value: 'assigned', label: 'Assigned' },
  { value: 'unassigned', label: 'Unassigned' },
  { value: 'requested', label: 'Requested' },
  { value: 'invited', label: 'Invited' }
];

// Integrations Source: -> Integrations Page & Select Source Modal

export const PROVIDERS = {
  GOOGLE: 'google',
  SLACK: 'slack',
  NOTION: 'notion',
  JIRA: 'jira',
  INTERNET: 'internet',
  WEB: 'web',
  WIKIPEDIA: 'wikipedia',
  CONFLUENCE: 'confluence',
  CONFLUENCE_ON_PREM: 'confluenceonprem',
  CUSTOM_SOURCES: 'custom',
  APIDECK_INTEGRATIONS: 'other',
  HUBSPOT: 'hubspot',
  COPPER: 'copper',
  ZENDESKSELL: 'zendesksell',
  PIPEDRIVE: 'pipedrive',
  INTERCOM: 'intercom',
  AZUREDEVOPS: 'azuredevops',
  GOOGLE_WORKSPACE: 'googleworkspace',
  BASECAMP: 'basecamp',
  AIRTABLE: 'airtable',
  YOUTRACK: 'youtrack',
  LINEAR: 'linear',
  MONDAY_COM: 'mondaycom',
  SHAREPOINT: 'sharepoint',
  CODA: 'coda',
  ZENDESK: 'zendesk',
  ZENDESK_HELP_CENTER: 'zendesk-help-center',
  CAPSULE: 'capsule',
  DROPBOX: 'dropbox',
  BITBUCKET: 'bitbucket',
  ONEDRIVE: 'onedrive',
  README: 'readme',
  TODOIST: 'todoist'
};

export const APIDECK_INTEGRATIONS = [
  'asana',
  'clickup',
  'helpscout',
  'helpscoutdocs',
  'gorgias',
  'trello',
  'freshdesk',
  'azuredevops',
  'zohocrm',
  'close',
  'copper',
  'hubspot',
  'pipedrive',
  'salesforce',
  'linear',
  'notion',
  'confluence',
  'confluenceonprem',
  'jira',
  'keka',
  'hibob',
  'hrcloud',
  'bamboohr',
  'humaans',
  'zohopeople',
  'greythr',
  'razorpayxpayroll',
  'googleworkspace',
  'okta',
  'haileyhr',
  'gitlab',
  'canny',
  'square',
  'dropbox',
  'box',
  'freshsales',
  'bitbucket',
  'accelo',
  'activecampaign',
  'googlecalendar',
  'outlookcalendar',
  PROVIDERS.HUBSPOT,
  PROVIDERS.ZENDESK,
  PROVIDERS.ZENDESKSELL,
  PROVIDERS.PIPEDRIVE,
  PROVIDERS.INTERCOM,
  PROVIDERS.BASECAMP,
  PROVIDERS.AIRTABLE,
  PROVIDERS.YOUTRACK,
  PROVIDERS.MONDAY_COM,
  PROVIDERS.SHAREPOINT,
  PROVIDERS.CODA,
  PROVIDERS.ZENDESK_HELP_CENTER,
  PROVIDERS.CAPSULE,
  PROVIDERS.ONEDRIVE,
  PROVIDERS.README,
  PROVIDERS.TODOIST
];

// Response Completed Using -> Mock APIS
export const COMPLETED_USING = ['ai', 'training'];

export const DOCS_PER_PAGE = 9;

export const NAVBAR_ROUTES = {
  BILLING: '/billing',
  DASHBOARD: '/',
  INDIVIDUAL_PLAN: '/individual-plan',
  INTEGRATIONS: '/integrations',
  AUTH: {
    SIGNIN: '/login',
    SIGNUP: '/signup',
    SETUP: '/setup',
    JOIN: '/join',
    INVITE: '/invite',
    RESET_PASSWORD: '/reset-password'
  },

  SETTINGS: '/settings',
  QUESTIONS: '/questions',
  FIRST_USER_LOGIN: '/first-user-login',
  INTEGRATIONS_PROVIDERS: {
    ROUTE: '/integrations/:provider',
    GOOGLE: '/integrations/google',
    SLACK: '/integrations/slack',
    WEB: '/integrations/web',
    JIRA: '/integrations/jira',
    CUSTOM_SOURCES: '/integrations/custom',
    HUBSPOT: '/integrations/hubspot',
    INTERCOM: '/integrations/intercom',
    COPPER: '/integrations/copper',
    ZENDESKSELL: '/integrations/zendesksell',
    PIPEDRIVE: '/integrations/pipedrive'
  },
  WEB_ADD_SOURCE: '/integrations/web/:teamId/addSource',
  WEB_MANAGE_SOURCES: '/integrations/web/:teamId/manageSources',
  SETTINGS_TABS: {
    ROUTE: '/settings/:tab/',
    GENERAL: '/settings/general',
    MY_ACCOUNT: '/settings/my-account',
    COMPANY: '/settings/company',
    COMPANY_ADMIN: '/settings/company-admins',
    PERSONAS: '/settings/personas',
    SEARCH_CAPABILITIES: '/settings/search-capabilities',
    CONNECTIONS: '/settings/connections',
    AUTO_ANSWER: '/settings/auto-answer',
    AUTOMATIONS: '/settings/automations',
    CUSTOM_APIS: '/settings/custom-apis'
  },
  SETTINGS_SUB_TABS: {
    ROUTE: '/settings/:tab/:subTab',
    AUTOMATIONS: {
      ZAPIER: '/settings/automations/zapier'
    }
  },
  TEAMS: '/teams',
  TEAM: {
    ROUTE: '/teams/:teamId'
  },
  ON_BOARDING: '/on-boarding',
  CHAT_WIDGETS: '/chat-widgets',
  CHAT_WIDGET: {
    ROUTE: '/chat-widgets/:widgetId'
  },
  ASK_CHAI: '/ask-chai',
  ACTIVITY_LOGS: '/activity-logs',
  ACTIONS: '/actions',
  SURVEY_REPORTS: '/survey-reports'
};

export const VIEW_TYPE = {
  GRID: 'grid',
  LIST: 'list'
};

export const QUESTIONS_PAGE_TABS = {
  NEEDS_ATTENTION: 'Needs_Attention',
  EVERYTHING_ELSE: 'Everything_Else'
};

export const ENV = {
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
  LOCAL: 'local'
};

export const TRUTO_CONNECTIONS_WITH_ADD_SOURCE = [
  'asana',
  'trello',
  'azuredevops',
  'clickup',
  'confluence',
  'confluenceonprem',
  'notion',
  'jira',
  'basecamp',
  'airtable',
  'youtrack',
  'linear',
  'mondaycom',
  'sharepoint',
  'gitlab',
  'canny',
  'dropbox',
  'box',
  'bitbucket',
  'coda',
  'helpscoutdocs',
  'zendesk-help-center',
  'onedrive',
  'readme',
  'todoist'
];

export const TRUTO_CONNECTIONS_WITH_PROJECT_NAME = [
  'asana',
  'notion',
  'confluence',
  'confluenceonprem',
  'jira',
  'azuredevops',
  'clickup',
  'trello',
  'basecamp',
  'airtable',
  'youtrack',
  'linear',
  'mondaycom',
  'sharepoint',
  'gitlab',
  'canny',
  'dropbox',
  'box',
  'bitbucket',
  'coda',
  'helpscoutdocs',
  'zendesk-help-center',
  'onedrive',
  'readme',
  'todoist'
];

export const TRUTO_CONNECTORS_NAME_MAPPING = {
  accelo: 'Accelo',
  activecampaign: 'ActiveCampaign',
  airtable: 'Airtable',
  asana: 'Asana',
  azuredevops: 'Azure DevOps',
  bamboohr: 'BambooHR',
  basecamp: 'Basecamp',
  bitbucket: 'Bitbucket',
  canny: 'Canny',
  capsule: 'Capsule',
  clickup: 'ClickUp',
  close: 'Close',
  coda: 'Coda',
  confluence: 'Confluence',
  confluenceonprem: 'Confluence On Prem',
  copper: 'Copper',
  dropbox: 'DropBox',
  freshdesk: 'Freshdesk',
  freshsales: 'Freshsales',
  gitlab: 'GitLab',
  googleworkspace: 'Google Workspace',
  gorgias: 'Gorgias',
  greythr: 'GreytHR',
  haileyhr: 'HaileyHR',
  helpscout: 'Help Scout - Inbox',
  helpscoutdocs: 'Help Scout - Docs',
  hibob: 'HiBob',
  hrcloud: 'HR Cloud',
  hubspot: 'HubSpot',
  humaans: 'Humaans',
  intercom: 'Intercom',
  jira: 'Jira',
  keka: 'Keka',
  linear: 'Linear',
  mondaycom: 'monday.com',
  notion: 'Notion',
  okta: 'Okta',
  onedrive: 'OneDrive',
  pipedrive: 'Pipedrive',
  razorpayxpayroll: 'RazorpayX Payroll',
  readme: 'ReadMe',
  salesforce: 'Salesforce',
  sharepoint: 'SharePoint',
  square: 'Square Payroll',
  todoist: 'Todoist',
  trello: 'Trello',
  youtrack: 'YouTrack',
  zendesk: 'Zendesk',
  zendesksell: 'Zendesk Sell',
  'zendesk-help-center': 'Zendesk - Help Center',
  zohocrm: 'Zoho CRM',
  zohopeople: 'Zoho People'
};

export const ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS =
  import.meta.env.VITE_NODE_ENV === ENV.PRODUCTION ? 99 : 9;

export const ROWS_PER_PAGE_FOR_OTHER_TABLES = 10;

export const PROMPT_IDEA_TYPES = {
  COMPANY_WIKI: 'CompanyWiki',
  OPEN_AI: 'OpenAI'
};

export const CONNECTION_SYNC_STATUS = {
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  FAILED: 'failed'
};

export const TUTORIAL_STATUS = {
  NOT_STARTED: 'not_started',
  STARTED: 'started',
  MINIMISED: 'minimised',
  COMPLETED: 'completed'
};

export const EMPTY_STATE_TYPE = {
  EXHAUSTED_QUESTIONS: 'exhausted-questions',
  FREE_PLAN_QUESTIONS_EXHAUSTED: 'free-plan-questions-exhausted',
  UNLICENSED_USER: 'unlicensed-user',
  SUBSCRIPTION_INACTIVE: 'subscription-inactive'
};

export const INTEGRATIONS_TABLE_HEADERS = [
  'Source',
  'Last trained on',
  'Uploaded by',
  'Status',
  ' '
];

export const USER_TYPE = {
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  TEAM_ADMIN: 'TEAM_ADMIN',
  USER: 'USER'
};

export const PRIVILEGE_CHART = {
  COMPANY_ADMIN: [], //all accessible for company admin,
  TEAM_ADMIN: [
    { path: NAVBAR_ROUTES.ASK_CHAI },
    { path: NAVBAR_ROUTES.INTEGRATIONS },
    { path: `${NAVBAR_ROUTES.INTEGRATIONS}/:teamId` },
    { path: `${NAVBAR_ROUTES.INTEGRATIONS}/:provider/:teamId` },
    { path: `${NAVBAR_ROUTES.INTEGRATIONS}/:provider/:teamId/addSource` },
    { path: `${NAVBAR_ROUTES.INTEGRATIONS}/:provider/:teamId/manageSources` },
    { path: NAVBAR_ROUTES.TEAMS },
    { path: NAVBAR_ROUTES.SETTINGS },
    { path: NAVBAR_ROUTES.ACTIONS },
    { path: NAVBAR_ROUTES.SETTINGS_TABS.MY_ACCOUNT },
    { path: NAVBAR_ROUTES.SETTINGS_TABS.CONNECTIONS },
    { path: NAVBAR_ROUTES.SETTINGS_TABS.AUTOMATIONS },
    { path: NAVBAR_ROUTES.SETTINGS_SUB_TABS.AUTOMATIONS.ZAPIER },
    { path: NAVBAR_ROUTES.SURVEY_REPORTS }
  ],
  USER: [
    { path: NAVBAR_ROUTES.ASK_CHAI },
    { path: NAVBAR_ROUTES.SETTINGS },
    { path: NAVBAR_ROUTES.SETTINGS_TABS.MY_ACCOUNT },
    { path: NAVBAR_ROUTES.ACTIONS }
  ]
};

export const EMOJI_MAP = {
  ':white_frowning_face:': '☹️',
  ':face_with_monocle:': '🧐'
};

export const TRAINING_TYPE = {
  FIRST_TIME: 'First time',
  AUTOMATIC_RETRAIN: 'Automatic retrain',
  FORCE_RETRAIN: 'Force refresh by user',
  FORCE_RETRAIN_BACKEND: 'Force refresh from backend',
  CHANNEL_UNARCHIVED: 'Channel unarchived by user',
  SLACK_APP_REINSTALLED: 'Slack app reinstalled',
  ZAPIER_AUTOMATION: 'Zapier automation',
  RECRAWL: 'Recrawl'
};

export const GOOGLE = {
  MIME_TYPES: {
    PDF: 'application/pdf',
    CSV: 'text/csv',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    SHEET: 'application/vnd.google-apps.spreadsheet',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    DOC: 'application/vnd.google-apps.document',
    PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    SLIDE: 'application/vnd.google-apps.presentation'
  },
  MIME_TYPES_TO_DOC_TYPES: {
    'application/msword': 'google_doc',
    'application/pdf': 'google_pdf',
    'application/rtf': 'google_rtf',
    'application/vnd.google-apps.document': 'google_doc',
    'application/vnd.google-apps.presentation': 'google_slide',
    'application/vnd.google-apps.spreadsheet': 'google_sheet',
    'application/vnd.ms-powerpoint': 'google_ppt',
    'application/vnd.oasis.opendocument.text': 'google_odt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'google_ppt',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'google_xlsx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'google_docx',
    'text/csv': 'google_csv'
  }
};

export const DOCUMENT_SOURCE_TYPES = {
  GOOGLE_DOC: 'google_doc',
  GOOGLE_SHEET: 'google_sheet',
  GOOGLE_PDF: 'google_pdf',
  GOOGLE_PPT: 'google_ppt',
  GOOGLE_DOCX: 'google_docx',
  GOOGLE_CSV: 'google_csv',
  GOOGLE_XLSX: 'google_xlsx',
  GOOGLE_SLIDE: 'google_slide',
  PDF: 'pdf',
  XLSX: 'xlsx',
  DOCX: 'docx',
  CSV: 'csv',
  PPTX: 'pptx'
};

export const QUERY_ROUTING = {
  IMAGE: 'ImageGeneration'
};

export const SUPER_QUERY_CONNECTORS = ['confluence', 'notion', 'sharepoint', 'onedrive'];
export const RAPID_FORM_MESSAGES = {
  PREVENT_DESELECT: 'This item is a source in your wiki.'
};
export const LATEST_MODEL_UPDATE_YEAR = '2024';
export const LATEST_MODEL_UPDATE_MONTH = 'August, 2024';
