import { toast } from 'react-toastify';
import { redirect } from 'react-router-dom';
import * as emoji from 'node-emoji';
import { EMOJI_MAP, NAVBAR_ROUTES } from './constants';

export const getSlackAppUrl = (teamId) =>
  `slack://app?team=${teamId}&id=${import.meta.env.VITE_SLACK_APP_ID}&tab=home`;

export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

const isValidJson = (jsonStr) => {
  try {
    const jsonObject = JSON.parse(jsonStr);
    return { isValid: true, jsonObject };
  } catch {
    return { isValid: false };
  }
};

/**
 * Extracts data from an input string containing a JSON object preceded by '[Start]' and followed by '[Done]'.
 *
 * @param {string} inputString - The input string to process.
 *
 * @returns {Object} An object containing the extracted data:
 *   - startValue {Object|null} - The parsed JSON object extracted from '[Start]' (or null if not found).
 *   - resultString {string} - The inputString with '[Start]' and '[Done]' removed or the original string if '[Done]' || 'Start]' is not found.
 *   - endValue {Object|null} - The parsed JSON object extracted from '[Done]' (or null if not found).
 */

export function extractStartEndValue(inputString) {
  // Define regular expression to match [START] [DONE] and JSON Object
  const startRegex = /\[START\]({[^[]*})/i;
  const jsonRegex = /^({.*})/;
  const doneRegex = /\[DONE\]/i;

  // Split the inputString at [DONE]
  const splittedString = inputString.split(doneRegex);

  let startValue = null;
  let resultString = '';
  let endValue = null;

  // Check for [START] match outside the loop
  const startMatch = splittedString[0].match(startRegex);
  if (startMatch) {
    const { jsonObject, isValid } = isValidJson(startMatch[1]);
    startValue = isValid ? jsonObject : null;
    resultString += splittedString[0].substring(startMatch[0].length);
  } else {
    resultString += splittedString[0];
  }

  // Continue processing the remaining parts
  for (let index = 1; index < splittedString.length; index++) {
    const currentString = splittedString[index];

    const jsonMatch = currentString.match(jsonRegex);
    if (jsonMatch) {
      const { jsonObject, isValid } = isValidJson(jsonMatch[1]);
      if (isValid && index === splittedString.length - 1) {
        endValue = jsonObject;
      }
    } else {
      resultString += currentString;
    }
  }

  return {
    startValue,
    resultString,
    endValue
  };
}

export function checkTheStatus(inputString) {
  // Define a regular expression to match the object pattern
  // Define a regular expression to match the object pattern with an optional "status" field
  const regex = /\{.*?"ok":(true|false),\s*"message":"(.*?)".*?"state":"(.*?)".*?\}/;

  // Try to match the regex in the inputString
  const match = regex.exec(inputString);

  if (match) {
    // Extract the matched values
    const ok = match[1] === 'true'; // Convert the "ok" value to a boolean
    const message = match[2];
    const state = match[3] || null; // Use null if "state" is not present

    return {
      ok,
      message,
      state
    };
  } else {
    // If the object pattern is not found, return the original input string
    return inputString;
  }
}

export const stringDecoder = (str) => {
  if (typeof str !== 'string') {
    return str;
  }
  try {
    return decodeURIComponent(str);
  } catch (e) {
    if (e instanceof URIError) {
      // Return the original string if it cannot be decoded
      return str;
    } else {
      // Re-throw other unexpected errors
      throw e;
    }
  }
};

/**
 * Handles API errors and displays an error message using toast if the status code is not 401 or 403.
 * @param {object} error - The error object containing status and data properties.
 * @param {string | null} message - Optional custom message to display on error.
 */
export function handleApiError(error, message = null) {
  if (error?.status === 403) {
    redirect(NAVBAR_ROUTES.ASK_CHAI);
  }

  if (![401, 403].includes(error?.status)) {
    toast.error(message ?? error.data?.message ?? 'Something went wrong!');
  }
}

export const showToastMessage = (message = '', isAlert = false) => {
  isAlert ? toast.warn(message) : toast.success(message);
};

export const emailRegex = /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const emojiFy = (text) => {
  let emojifiedText = text;
  if (!emojifiedText?.length) {
    return;
  }

  emojifiedText = emoji.emojify(emojifiedText);
  //custom emoji mapping for which emoji.emojify fails
  emojifiedText = emojifiedText.replace(/:[a-zA-Z_]+:/g, (match) => EMOJI_MAP[match] || match);

  return emojifiedText;
};

/**
 * Validates the file upload by checking for double extensions and disallowed extensions.
 * @param {File} file - The file to be validated.
 * @returns {boolean} - Returns true if the file extension is allowed, otherwise false.
 */
export function validateFileUpload(file) {
  const disallowedExtensions = [
    'exe',
    'bat',
    'cmd',
    'com',
    'cpl',
    'dll',
    'dmg',
    'iso',
    'jar',
    'js',
    'jsp',
    'msi',
    'php',
    'py',
    'rb',
    'sh',
    'scr',
    'vbs',
    'wsf'
  ];

  const fileName = file.name.toLowerCase();
  // Split the filename by periods
  const fileParts = fileName.split('.');
  const fileExtension = fileParts.pop();
  if (disallowedExtensions.includes(fileExtension)) {
    toast.error(`Files with the .${fileExtension} extension are not allowed.`);
    return false;
  }

  // Check for double extensions where the second last extension is disallowed
  if (fileParts.length > 1) {
    const secondLastExtension = fileParts.pop();
    if (disallowedExtensions.includes(secondLastExtension)) {
      toast.error('Files with double extensions are not allowed.');
      return false;
    }
  }
  return true;
}
