import { useState } from 'react';
import { Button, Flex, Text, Textarea } from '@mantine/core';
import { func } from 'prop-types';
import styles from './styles.module.scss';

const AnswerForm = ({ handleCancel, handleAnswer }) => {
  const [value, setValue] = useState('');

  return (
    <>
      <Textarea
        classNames={{
          input: styles.input
        }}
        placeholder='Write your answer here.'
        size='sm'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        autosize
        minRows={6}
        maxRows={6}
      />
      <Text mt={12} size='xs'>
        Note: Chai will send your answer to the teammate who asked for help. Chai will also ingest
        your answer and use it to answer similar questions more accurately in the future.
      </Text>
      <Flex justify={'end'} gap={22} align={'center'} mt={17} mb={26}>
        <Text className={styles.cancel} onClick={handleCancel}>
          Cancel
        </Text>
        <Button
          className='primaryBtn'
          disabled={value.trim().length === 0}
          onClick={() => handleAnswer(value)}
        >
          Send answer
        </Button>
      </Flex>
    </>
  );
};

AnswerForm.propTypes = {
  handleCancel: func.isRequired,
  handleAnswer: func.isRequired
};

export default AnswerForm;
