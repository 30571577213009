import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedValue, usePagination } from '@mantine/hooks';
import { useParams } from 'react-router-dom';
import { useFetchSourcesQuery } from 'redux/services/integrations';
import SourcesDataView from './SourcesDataView/index.jsx';
import { PROVIDERS, ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS } from 'utils/constants';

const CustomSources = (props) => {
  const { user, viewType, setViewType, teams } = props;
  const { teamId } = useParams();
  const [page, onChange] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue] = useDebouncedValue(searchValue, 500);
  const useLlamaparser = teams.find((team) => team.teamId === teamId)?.textTableExtraction || false;
  const payload = {
    provider: PROVIDERS.CUSTOM_SOURCES,
    params: {
      teamId,
      page: debouncedValue ? undefined : page,
      limit: debouncedValue ? undefined : ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS,
      q: debouncedValue ? debouncedValue : undefined,
      sort: 'lastTrainingStartedAt'
    }
  };
  const {
    data: { data: customSources = [], totalCount = 0 } = {},
    isLoading: isCustomLoading,
    isFetching: isCustomFetching
  } = useFetchSourcesQuery(
    { ...payload },
    {
      refetchOnMountOrArgChange: true
    }
  );

  const pagination = usePagination({
    page,
    onChange
  });

  return (
    <>
      <SourcesDataView
        viewType={viewType}
        setViewType={setViewType}
        sources={customSources}
        user={user}
        isLoading={isCustomLoading || isCustomFetching}
        pagination={pagination}
        pageCount={Math.ceil(totalCount / ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS)}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        debouncedValue={debouncedValue}
        totalCount={totalCount}
        metaPayload={payload}
        useLlamaparser={useLlamaparser}
      />
    </>
  );
};

CustomSources.propTypes = {
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
  user: PropTypes.object,
  teams: PropTypes.array
};

CustomSources.defaultProps = {
  viewType: 'grid',
  setViewType: () => null,
  user: {},
  teams: []
};

export default CustomSources;
