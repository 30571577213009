import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const InvitedByFormHeader = ({ invitedBy, allowedDomains }) => (
  <>
    <div className={styles.inviteText}>
      <span className={styles.invitedBy}>{invitedBy}</span> is inviting you to join their company on
      Chat Aid
    </div>
    <div className={allowedDomains.length > 0 ? styles.domainsBox : styles.hidden}>
      You can join Chat Aid via&nbsp;
      {allowedDomains.map((domain, index) => {
        return (
          <span key={index}>
            @{domain}
            {index === allowedDomains.length - 1 ? '' : ', '}
          </span>
        );
      })}
      &nbsp;email ID
    </div>
  </>
);

InvitedByFormHeader.propTypes = {
  invitedBy: PropTypes.string.isRequired,
  allowedDomains: PropTypes.array.isRequired
};

export default InvitedByFormHeader;
