export const DOCUMENT = '/document';
export const GET_DOCUMENTS = '/dashboard/documents';
export const PLAN = '/dashboard/me/plan';
export const LOGIN = '/dashboard/login';
export const GOOGLE_AUTH = '/auth/google/redirect';
export const CHECKOUT = '/billing/checkout-session';
export const CUSTOMER_PORTAL = '/billing/customer-portal';
export const LICENSE = '/license';
export const LICENSE_USERS = '/license/users';
export const LICENSE_REQUESTS = '/license/requests';
export const CHAT_ESCALATION_SUMMARY = '/chat/escalation/summary';
export const SETTINGS = '/settings';
export const USER = '/dashboard/me';
export const USER_CONNECTION_TOKEN = '/dashboard/me/connection-token';
export const LOGOUT = '/dashboard/logout';
export const WIKI_SEARCH = '/wikipedia';
export const SLACK_CONNECT = '/slack-connect';
export const TEAMS = '/teams';
export const TEAM = '/team';
export const APIDECK_VAULT = '/apideck/session';
export const WIDGET = '/widget';
export const WIDGETS = '/widgets';
export const CHAT_HISTORY = '/chat/history';
export const CHAT = '/chat';
export const INTEGRATIONS_SOURCES = '/integrations/sources';
export const PROMPT_IDEAS = '/integrations/prompt-ideas';
export const REQUEST_LICENSE = '/license';
export const ADD_ON_QUESTIONS = '/billing/add-on-questions';
export const FETCH_SLACK_CHANNELS = '/channels';
export const INVITE = '/invite';
export const INVITE_VIA_LINK = '/invite/join';
export const INVITE_REVOKE = '/invite/revoke';
export const CHATS_COMPLETION = '/chat/completions';
export const GOOGLE = '/google';

export const END_POINT = {
  AUTOMATIONS: {
    GENERATE_API_KEY: '/auth/api-key'
  },
  AUTH: {
    DASHBOARD_LOGIN: '/dashboard/login',
    DASHBOARD_SIGNUP: '/dashboard/signup',
    RESEND_VERIFICATION: '/auth/resend-verification',
    USER_FORGOT_PASSWORD: '/user/forgot-password',
    USER_PASSWORD_RESET: '/user/password-reset',
    SETUP_COMPANY_LIST: '/setup/company/list',
    SETUP_COMPANY_JOIN: '/setup/company/join',
    SETUP_COMPANY_LOGIN: '/setup/company/login',
    SETUP_COMPANY: '/setup/company',
    SETUP_USER: '/setup/user'
  },
  DASHBOARD_USERS: '/dashboard/users',
  DASHBOARD_ADMINS: '/settings/admins',
  INTEGRATIONS: '/integrations',
  INTEGRATIONS_SOURCE: '/integrations/source', // To do: remove this endpont
  INTEGRATIONS_SOURCES: '/integrations/sources/delete',
  INTEGRATOINS_DESCRIPTION: '/integrations/description',
  INTEGRATIONS_SUB_DOCUMENTS: '/integrations/sub-documents',
  INTEGRATIONS_SOURCE_CRAWL: '/integrations/source/crawl',
  INTEGRATIONS_SOURCES_TRAIN: '/integrations/sources/train',
  ATLASSIAN: {
    DOMAINS: '/atlassian/domains',
    PROJECTS: '/atlassian/projects',
    SPACES: '/atlassian/spaces'
  },
  SITEMAP: '/sitemap/urls',
  VALID_SITEMAP: '/sitemap/validate',
  CONNECTOR: {
    ENABLE: '/integrations/enable'
  },
  SETTINGS: {
    COMPANY: '/settings/company',
    USER: '/settings/user',
    USER_ORGANISATION: '/user/organisation',
    DASHBOARD_ORGANISATION: '/dashboard/organisation',
    AUTO_ANSWER_CHANNELS: '/settings/auto-answer/channels',
    PERSONA: '/settings/persona',
    CUSTOM_API: '/settings/custom',
    CUSTOM_APIS: '/settings/custom-apis',
    CONNECTION_STATUS: '/settings/connections/status'
  },
  USER: {
    ORGANISATIONS: '/user/organisations',
    SWITCH_ORGANISATION: '/user/switch-organisation'
  },
  ON_BOARDING: {
    INSTALLER: '/dashboard/onboarding/installer'
  },
  STATS: {
    MONTHLY_INSIGHTS: '/stats/monthly-insights'
  },
  SURVEY_REPORTS: {
    FETCH_SURVEY_REPORTS: '/survey-reports',
    FETCH_REPORT_TABLES: '/survey-reports/tables',
    GENERATE_DESCRIPTION: '/survey-reports/tables/description',
    DELETE_TABLE: '/survey-reports/table',
    UPDATE_TABLE: '/survey-reports/table',
    GENERATE_COMPARISON_DATASET: '/survey-reports/comparison-dataset'
  }
};
