import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Skeleton, Button } from '@mantine/core';
import View from './View';
import {
  useFetchReportTablesQuery,
  useGenerateTablesDescriptionMutation,
  useDeleteTableMutation,
  useUpdateTableMutation
} from 'redux/services/survey-reports';
import styles from './styles.module.scss';

const Tables = ({ report }) => {
  const { data, isLoading } = useFetchReportTablesQuery({ documentId: report.id });
  const [generateTablesDescription, { isLoading: isGeneratingDescription }] =
    useGenerateTablesDescriptionMutation();
  const [deleteTable] = useDeleteTableMutation();
  const [updateTable] = useUpdateTableMutation();

  const handleGenerateDescription = async () => {
    try {
      await generateTablesDescription(report.id).unwrap();
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  const handleDeleteTable = async (tableName) => {
    try {
      await deleteTable({ documentId: report.id, tableName }).unwrap();
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  const handleSaveClick = async ({
    editedElements,
    headers,
    tableName,
    description,
    rawDescription
  }) => {
    const body = {
      data: editedElements,
      headers,
      description,
      rawDescription
    };
    try {
      await updateTable({ documentId: report.id, tableData: body, tableName }).unwrap();
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  return (
    <div className={styles.surveyReportsTables}>
      {isLoading && (
        <>
          🔍 fetching report tables
          <Skeleton height={20} width='100%' mt={6} radius='xl' />
          <Skeleton height={20} width='100%' mt={6} radius='xl' />
        </>
      )}
      {!isLoading && (
        <div className={styles.generateDescriptionBtn}>
          <Button
            className='primaryBtn'
            loading={isGeneratingDescription}
            onClick={handleGenerateDescription}
          >
            Generate descriptions
          </Button>
        </div>
      )}
      {data &&
        !isLoading &&
        Object.keys(data).map((tableKey, index) => (
          <div key={tableKey + index}>
            <View
              response={data[tableKey]}
              tableKey={tableKey}
              handleDeleteTable={handleDeleteTable}
              handleSaveClick={handleSaveClick}
              tableName={`Table ${index + 1}`}
            />
          </div>
        ))}
    </div>
  );
};

Tables.defaultProps = {
  report: {}
};

Tables.propTypes = {
  report: PropTypes.object
};

export default Tables;
