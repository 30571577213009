import PropTypes, { func } from 'prop-types';
import { useMediaQuery } from '@mantine/hooks';
import { Modal, Title, Flex, Button, Image } from '@mantine/core';
import GoogleIntegrationIcon from 'assets/icons/google-integration.svg';
import styles from './styles.module.scss';

const Disclosure = ({ opened, setOpened, handleContinue }) => {
  const screen767 = useMediaQuery('(max-width: 767px)');

  return (
    <>
      <Modal
        centered
        opened={opened}
        size={570}
        radius={4}
        className='mobile-modal'
        onClose={() => setOpened(false)}
        styles={{
          header: { alignItems: 'flex-start' }
        }}
        title={
          <Flex direction='column' gap='lg'>
            <Image src={GoogleIntegrationIcon} alt='Google' w={186} />
            <Title className={styles.pageTitle}>Connecting Google Drive™ with Chat Aid </Title>
          </Flex>
        }
      >
        <div className={styles.disclosure}>
          Chat Aid stores information received from Google APIs in our database in a non-human
          readable format. Your data is uniquely stored and no data is transferred or sold to third
          parties for advertising, information reselling and other purposes. Only necessary data is
          sent to OpenAI to answer your questions. This use of data complies with&nbsp;
          <span
            className={styles.hyperLink}
            onClick={() =>
              window.open('https://developers.google.com/terms/api-services-user-data-policy')
            }
          >
            Google API Services User Data Policy
          </span>
          , including the Limited Use requirements.
        </div>
        <Flex mt={50} justify='flex-end'>
          <Button
            variant='outline'
            radius={4}
            color='dark'
            onClick={() => {
              setOpened(false);
            }}
            style={{ height: screen767 ? 35 : 42 }}
          >
            {handleContinue ? 'Cancel' : 'Close'}
          </Button>
          {handleContinue && (
            <Button
              className='primaryBtn'
              onClick={() => {
                setOpened(false);
                handleContinue();
              }}
              ml={20}
            >
              Continue
            </Button>
          )}
        </Flex>
      </Modal>
    </>
  );
};

Disclosure.propTypes = {
  opened: PropTypes.bool.isRequired,
  setOpened: PropTypes.func.isRequired,
  handleContinue: func
};

export default Disclosure;
