import InformationOne from 'assets/automations/information-one.svg';
import InformationTwo from 'assets/automations/information-two.svg';
import InformationThree from 'assets/automations/information-three.svg';
import { Anchor } from '@mantine/core';

export const data = [
  {
    icon: InformationOne,
    title: 'Automatically train on new files added to Google Drive™',
    desc: 'Create a Zap to trigger training when a new file is added to your Google Drive or a specific folder in it.'
  },
  {
    icon: InformationTwo,
    title: 'More Zapier automations',
    desc: 'Support for more Zapier integrations and Chat Aid actions like answering questions and training data from other connections are coming soon.'
  },
  {
    icon: InformationThree,
    title: 'Integrate Chat Aid into your own workflow',
    desc: (
      <>
        Have more use cases? Drop us an email at&nbsp;
        <Anchor
          underline='always'
          size='xs'
          c='primaryGreen.3'
          className='font-poppins'
          weight='bold'
          href='mailto:support@chataid.com'
        >
          support@chataid.com
        </Anchor>
        .
      </>
    )
  }
];
