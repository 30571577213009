import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Loader } from '@mantine/core';
import Layout from 'components/Layout';
import EmptyStates from 'components/EmptyStates';
import Chats from './Chats/View';
import PromptIdeas from './PromptIdeas/IdeaSelection/View';
import AskQueryInput from './AskQueryInput';
import Conversation from './Conversation/View';
import Header from 'pages/OnBoarding/Components/Header/View';
import WelcomeModal from 'pages/OnBoarding/Components/WelcomeModal/View';
import QuestionsExhaustedDialogBox from './QuestionsExhaustedDialogBox';
import { EMPTY_STATE_TYPE } from 'utils/constants';
import styles from './styles.module.scss';

const View = (props) => {
  const {
    chatHistory,
    isLoading,
    selectedChat,
    handleSelectChat,
    conversation,
    conversationLoading,
    setShowPromptIdeas,
    setConversation,
    hideChats,
    setHideChats,
    showChatHistoryModal,
    setShowChatHistoryModal,
    answerCompletedCallback,
    handleSuggestedQuestionClick,
    handleOpenPromptIdeasPage,
    isOnBoarding,
    showWelcomeModalFromParams,
    wikiPromptIdeas,
    canGenerateQuestion,
    isFetching,
    isPolling,
    setIsPolling,
    provider,
    handleCloseWelcomeModal
  } = props;
  const { user, loading } = useSelector((state) => state.user);

  const {
    planInfo: { totalQuestionsRemaining = 0, hasSubscription = false, trialDaysLeft = 0 },
    loading: isPlanLoading
  } = useSelector((state) => state.plan);

  const url = new URL(window.location.href);
  const promptId = url.searchParams.get('promptId');

  if (isLoading || isPlanLoading) {
    return (
      <Layout pageTitle='Ask Chai'>
        <div className={styles.loadingState}>
          <Loader />
        </div>
      </Layout>
    );
  }

  if (Object.keys(user).length && hasSubscription && !user.isLicensed) {
    return (
      <Layout pageTitle='Ask Chai'>
        <div className={styles.loadingState}>
          <EmptyStates
            isAdmin={loading ? false : user.isAdmin}
            userAlreadyRequestedLicense={user?.isLicenseRequested}
            type={EMPTY_STATE_TYPE.UNLICENSED_USER}
          />
        </div>
      </Layout>
    );
  }

  return (
    <Layout pageTitle='Ask Chai'>
      <WelcomeModal
        name={user.name}
        open={showWelcomeModalFromParams}
        setOpen={handleCloseWelcomeModal}
      />
      <div className={styles.showChatHistoryMobile} onClick={() => setShowChatHistoryModal(true)}>
        Show chat history
      </div>
      <div className={styles.askAlbusContainer}>
        {isOnBoarding && <Header active={3} setActive={(prev) => prev + 1} />}
        <div className={styles.leftSection}>
          {isOnBoarding && <div className={styles.disabled}></div>}
          <Chats
            chats={chatHistory}
            selectedChat={selectedChat}
            handleChatSelect={handleSelectChat}
            hideChats={hideChats}
            setHideChats={setHideChats}
            showChatHistoryModal={showChatHistoryModal}
            setShowChatHistoryModal={setShowChatHistoryModal}
          />
        </div>
        <div className={styles.rightSection}>
          {totalQuestionsRemaining < 1 && hasSubscription && !selectedChat ? (
            <EmptyStates
              isAdmin={loading ? false : user.isAdmin}
              isTrialDaysCompleted={trialDaysLeft < 1}
              type={EMPTY_STATE_TYPE.EXHAUSTED_QUESTIONS}
            />
          ) : (
            <>
              {promptId || selectedChat ? (
                <Conversation
                  messages={conversation}
                  conversationLoading={conversationLoading}
                  handleSelectChat={handleSelectChat}
                  setConversation={setConversation}
                  hideChats={hideChats}
                  answerCompletedCallback={answerCompletedCallback}
                />
              ) : (
                <PromptIdeas
                  isOnboarding={isOnBoarding}
                  provider={provider}
                  handleSuggestedQuestionClick={handleSuggestedQuestionClick}
                  handleOpenPromptIdeasPage={handleOpenPromptIdeasPage}
                  wikiPromptIdeas={wikiPromptIdeas}
                  canGenerateQuestion={canGenerateQuestion}
                  isFetching={isFetching}
                  setIsPolling={setIsPolling}
                  isPolling={isPolling}
                />
              )}
              {hasSubscription && totalQuestionsRemaining <= 0 ? (
                <QuestionsExhaustedDialogBox />
              ) : (
                <AskQueryInput
                  selectedChat={selectedChat}
                  handleSelectChat={handleSelectChat}
                  setConversation={setConversation}
                  setShowPromptIdeas={setShowPromptIdeas}
                  isOnBoarding={isOnBoarding}
                  wikiPromptIdeas={wikiPromptIdeas}
                />
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

View.propTypes = {
  chatHistory: PropTypes.shape({
    today: PropTypes.arrayOf(
      PropTypes.shape({
        promptId: PropTypes.string,
        title: PropTypes.string
      })
    ).isRequired,
    yesterday: PropTypes.arrayOf(
      PropTypes.shape({
        promptId: PropTypes.string,
        title: PropTypes.string
      })
    ).isRequired,
    last7days: PropTypes.arrayOf(
      PropTypes.shape({
        promptId: PropTypes.string,
        title: PropTypes.string
      })
    ).isRequired,
    last30days: PropTypes.arrayOf(
      PropTypes.shape({
        promptId: PropTypes.string,
        title: PropTypes.string
      })
    ).isRequired
  }),
  isLoading: PropTypes.bool.isRequired,
  selectedChat: PropTypes.shape({
    promptId: PropTypes.string,
    title: PropTypes.string,
    parentTs: PropTypes.string
  }),
  handleSelectChat: PropTypes.func.isRequired,
  conversation: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      role: PropTypes.string.isRequired,
      citedSources: PropTypes.array,
      completedUsing: PropTypes.string
    })
  ).isRequired,
  conversationLoading: PropTypes.bool.isRequired,
  setShowPromptIdeas: PropTypes.func.isRequired,
  setConversation: PropTypes.func.isRequired,
  hideChats: PropTypes.bool.isRequired,
  setHideChats: PropTypes.func.isRequired,
  showChatHistoryModal: PropTypes.bool.isRequired,
  setShowChatHistoryModal: PropTypes.func.isRequired,
  answerCompletedCallback: PropTypes.func,
  handleSuggestedQuestionClick: PropTypes.func.isRequired,
  handleOpenPromptIdeasPage: PropTypes.func.isRequired,
  isOnBoarding: PropTypes.bool,
  showWelcomeModalFromParams: PropTypes.bool.isRequired,
  wikiPromptIdeas: PropTypes.array,
  canGenerateQuestion: PropTypes.bool,
  isFetching: PropTypes.bool,
  setIsPolling: PropTypes.func.isRequired,
  isPolling: PropTypes.bool.isRequired,
  provider: PropTypes.string,
  handleCloseWelcomeModal: PropTypes.func
};

View.defaultProps = {
  selectedChat: null,
  answerCompletedCallback: () => null,
  wikiPromptIdeas: [],
  hasNext: false,
  isFetching: false,
  setIsPolling: () => null,
  isPolling: false,
  provider: '',
  handleCloseWelcomeModal: () => null,
  isOnBoarding: false
};

export default View;
