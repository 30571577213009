import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDebouncedValue, usePagination } from '@mantine/hooks';
import integrationsApi, {
  useFetchSourcesQuery,
  useDisconnectSourcesMutation
} from 'redux/services/integrations';
import Header from './Header';
import MultiSelectActions from '../Components/MultiSelectActions';
import MessageEmptyState from './MessageEmptyState';
import ChannelsDataView from './ChannelsDataView';
import MessageDataView from './MessageDataView';
import EmptyState from '../Components/EmptyState';
import { PROVIDERS, ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS, NAVBAR_ROUTES } from 'utils/constants';
import { SOURCES_STATUS } from '../Web/constants';
import { SOURCE_TYPES } from 'pages/Integrations/utils';

const SlackSources = (props) => {
  const { user, viewType, setViewType } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { teamId } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const [page, onChange] = useState(1);
  const [sourceType, setSourceType] = useState(SOURCE_TYPES.CHANNELS);
  const [source, setSource] = useState({
    selectAll: false,
    showRemoveModal: false,
    selected: []
  });
  const [totalItems, setTotalItems] = useState([]);
  const selectedSources = totalItems.filter((item) => source.selected.includes(item?.id));
  const [debouncedValue] = useDebouncedValue(searchValue, 500);
  const [disconnectSources, { isLoading: isDisconnectSourcesLoading }] =
    useDisconnectSourcesMutation();
  const tooltipLabels = [
    'Unchecking individual sources when you ‘select all’ is not supported currently. Coming soon.',
    'You cannot select the sources which are under the process. Please wait until they turn active.'
  ];

  const payload = {
    provider: PROVIDERS.SLACK,
    params: {
      teamId,
      page: debouncedValue ? undefined : page,
      limit: debouncedValue ? undefined : ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS,
      q: debouncedValue ? debouncedValue : undefined,
      type: sourceType,
      sort: 'lastTrainingStartedAt'
    }
  };
  const {
    data: { data: sources = [], isAppUpdated, totalCount = 0 } = {},
    isLoading,
    isFetching,
    error
  } = useFetchSourcesQuery(
    { ...payload },
    {
      refetchOnMountOrArgChange: true
    }
  );
  const allInProcessing = sources.every((obj) => obj.status === SOURCES_STATUS.PROCESSING);
  const isProcessing = sources.some((source) => source.status === SOURCES_STATUS.PROCESSING);

  //When Chat Aid is removed from the Slack app configuration, wait for 2 sec on the Slack connection page to show a toast message before redirecting to the integrations page.
  if (error && !error?.data?.isAppConnected) {
    setTimeout(() => {
      navigate(NAVBAR_ROUTES.INTEGRATIONS);
    }, 2000);
  }

  const pagination = usePagination({
    page,
    onChange
  });

  async function handleDeleteSources() {
    const payload = {
      provider: PROVIDERS.SLACK,
      teamId,
      ...(source.selected.length ? { documentMongoIds: source.selected } : {})
    };

    const response = await disconnectSources(payload);
    if (response?.data?.ok) {
      toast.success(response.data.message);
      setTotalItems((prevItems) => prevItems.filter((item) => !source.selected.includes(item.id)));
      dispatch(integrationsApi.util.invalidateTags(['SourcesByProvider']));
      setSource((prevState) => ({
        ...prevState,
        showRemoveModal: false,
        selected: [],
        selectAll: false
      }));
    }
  }

  function handleSelectAll() {
    setSource((prevState) => ({
      ...prevState,
      selectAll: !prevState.selectAll,
      selected: []
    }));
  }

  function handleCheckboxCta(source) {
    setSource((prevState) => {
      const uncheckSelectedSource = prevState.selected.includes(source.id);

      const updatedSelectedSources = uncheckSelectedSource
        ? prevState.selected.filter((sourceId) => sourceId !== source.id)
        : [...prevState.selected, source.id];

      return {
        ...prevState,
        selected: updatedSelectedSources
      };
    });
  }

  function renderView() {
    if (!sources.length && !isLoading) {
      if (sourceType === SOURCE_TYPES.CHANNELS || debouncedValue.length) {
        return (
          <EmptyState
            sourceType={sourceType}
            isDisabled={!user.isAdmin}
            showUpdateModal={false}
            text={
              debouncedValue.length && !sources?.length
                ? `No ${
                    sourceType === SOURCE_TYPES.CHANNELS ? 'sources' : 'messages'
                  } matching "${searchValue}" found.`
                : undefined
            }
            hideButton={debouncedValue.length && !sources?.length}
          />
        );
      }
      return <MessageEmptyState />;
    }
    if (sourceType === SOURCE_TYPES.CHANNELS) {
      return (
        <ChannelsDataView
          selectedSources={source.selected}
          isAllSourcesSelected={source.selectAll}
          handleCheckboxCta={handleCheckboxCta}
          viewType={viewType}
          setViewType={setViewType}
          channels={sources}
          user={user}
          showUpdateModal={!isAppUpdated}
          isLoading={isLoading || isFetching}
          pagination={pagination}
          totalCount={totalCount}
          pageCount={Math.ceil(totalCount / ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS)}
          metaPayload={payload}
          totalItems={totalItems}
          setTotalItems={setTotalItems}
          checkboxTooltipLabels={tooltipLabels}
        />
      );
    }
    return (
      <MessageDataView
        selectedSources={source.selected}
        isAllSourcesSelected={source.selectAll}
        handleCheckboxCta={handleCheckboxCta}
        viewType={viewType}
        setViewType={setViewType}
        messages={sources}
        user={user}
        showUpdateModal={!isAppUpdated}
        isLoading={isLoading || isFetching}
        pagination={pagination}
        totalCount={totalCount}
        pageCount={Math.ceil(totalCount / ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS)}
        metaPayload={payload}
        totalItems={totalItems}
        setTotalItems={setTotalItems}
        checkboxTooltipLabels={tooltipLabels}
      />
    );
  }

  return (
    <>
      <Header
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        viewType={viewType}
        setViewType={setViewType}
        user={user}
        showUpdateModal={!isAppUpdated}
        sourceType={sourceType}
        pagination={pagination}
        handleSelectAll={handleSelectAll}
        sourcesCount={sources.length}
        disableSelectAll={allInProcessing}
        isAllSourcesSelected={source.selectAll}
        setSourceType={(value) => {
          //whenever source type changes, pagintation will be reset
          onChange(1);
          setSourceType(value);
        }}
      />
      {renderView()}
      {(source.selectAll || source.selected.length > 0) && (
        <MultiSelectActions
          selectedSources={selectedSources}
          isDisconnectSourcesLoading={isDisconnectSourcesLoading}
          selectAll={source.selectAll}
          isProcessing={isProcessing}
          handleDeleteSources={handleDeleteSources}
          showRemoveSourceModal={source.showRemoveModal}
          setShowRemoveSourceModal={(value) =>
            setSource((prevState) => ({ ...prevState, showRemoveModal: value }))
          }
        />
      )}
    </>
  );
};

SlackSources.defaultProps = {
  viewType: 'grid',
  setViewType: () => null,
  user: {}
};

SlackSources.propTypes = {
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
  user: PropTypes.object
};
export default SlackSources;
