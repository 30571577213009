import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  planInfo: {}
};

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    savePlanInfo: (state, action) => {
      let totalCount = 0;
      let planType = 'SUBSCRIBED';

      if (typeof action.payload?.addOnInteractionsLeft === 'number') {
        totalCount = totalCount + action.payload?.addOnInteractionsLeft;
      }
      if (typeof action.payload?.questionsLeft === 'number') {
        totalCount = totalCount + action.payload?.questionsLeft;
      }

      return {
        planInfo: {
          ...action.payload,
          totalQuestionsRemaining: Math.max(totalCount, 0),
          planType,
          isTrialExpired:
            action.payload.trialExpired ||
            (!action.payload?.trialExpired &&
              !action.payload?.hasSubscription &&
              !action.payload?.isTrial)
        },
        loading: false
      };
    },
    setQuestionsToZero: (state) => ({
      ...state,
      planInfo: {
        ...state.planInfo,
        questionsLeft: 0,
        addOnInteractionsLeft: 0,
        totalQuestionsRemaining: 0
      }
    }),
    updateQuestionCount: (state, action) => {
      const creditsUsed = action.payload;
      const totalQuestionsRemaining = state.planInfo.totalQuestionsRemaining - creditsUsed;

      if (state.planInfo.questionsLeft) {
        return {
          ...state,
          planInfo: {
            ...state.planInfo,
            questionsLeft: state.planInfo.questionsLeft - creditsUsed,
            totalQuestionsRemaining
          }
        };
      }

      return {
        ...state,
        planInfo: {
          ...state.planInfo,
          addOnInteractionsLeft: state.planInfo.addOnInteractionsLeft - creditsUsed,
          totalQuestionsRemaining
        }
      };
    },
    updateAddOnInteractionsLeft: (state, action) => {
      return {
        ...state,
        planInfo: {
          ...state.planInfo,
          addOnInteractionsLeft: action.payload
        }
      };
    },
    setFreeTrialExpired: (state) => ({
      ...state,
      planInfo: {
        ...state.planInfo,
        isTrialExpired: true
      }
    })
  }
});

export const {
  savePlanInfo,
  setQuestionsToZero,
  updateQuestionCount,
  updateAddOnInteractionsLeft,
  setFreeTrialExpired
} = planSlice.actions;

export default planSlice.reducer;
