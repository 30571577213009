import PropTypes from 'prop-types';
import { ActionIcon, Button, Flex, Group, Menu, Textarea, TextInput } from '@mantine/core';
import classnames from 'classnames';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from './styles.module.scss';

const CustomPersonaForm = (props) => {
  const { persona, customPersonaForm, handleCreateOrEditPersona, handleCancel, loading } = props;

  return (
    <form onSubmit={customPersonaForm.onSubmit((values) => handleCreateOrEditPersona(values))}>
      <TextInput
        label='Name'
        description='Name your persona'
        placeholder='Severus Snape'
        {...customPersonaForm.getInputProps('name')}
        mb={12}
      />
      <Textarea
        label='Definition'
        description='Define Chai’s personality, voice and behavior when he responds to teammates.'
        placeholder='Be precise, write short responses and keep a formal tone in your language.'
        classNames={{
          input: styles.textArea
        }}
        {...customPersonaForm.getInputProps('prompt')}
      />
      <Flex justify='flex-end' gap={8} align='center' mt={24}>
        <Button className='primaryBtnOutlineV2' onClick={handleCancel}>
          Cancel
        </Button>
        <Group wrap='nowrap' gap={0}>
          <Button
            disabled={loading}
            className={classnames('primaryBtn', styles.button)}
            type='submit'
          >
            {persona ? 'Save' : 'Create'}
          </Button>
          <Menu transitionProps={{ transition: 'pop' }} position='bottom-end' withinPortal>
            <Menu.Target>
              <ActionIcon
                variant='filled'
                size={36}
                className={styles.menuControl}
                loading={loading}
              >
                <SVGIcon name={ICONS_LIST.DOWN_ARROW} height={14} width={14} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={() => {
                  customPersonaForm.validate();
                  if (customPersonaForm.isValid()) {
                    handleCreateOrEditPersona({ ...customPersonaForm.values, activate: true });
                  }
                }}
              >
                {persona ? 'Save and select persona' : 'Create and select persona'}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Flex>
    </form>
  );
};

CustomPersonaForm.propTypes = {
  persona: PropTypes.shape({}),
  customPersonaForm: PropTypes.shape({
    onSubmit: PropTypes.func,
    getInputProps: PropTypes.func,
    isValid: PropTypes.func,
    validate: PropTypes.func,
    values: PropTypes.shape({})
  }).isRequired,
  handleCreateOrEditPersona: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

CustomPersonaForm.defaultProps = {
  persona: null,
  loading: false
};

export default CustomPersonaForm;
