import { Image, Modal, ScrollArea, Text, Title } from '@mantine/core';
import { func, number, arrayOf, shape, string } from 'prop-types';
import { returnIcon } from 'pages/Integrations/utils';
import styles from './styles.module.scss';

const UnproccessedFilesModal = ({ files, totalCount, onClose }) => {
  return (
    <Modal
      opened
      onClose={onClose}
      size={650}
      padding={24}
      radius={8}
      title={
        <Title size={18} fw={800}>
          Chai cannot train on some sources
        </Title>
      }
      className='mobile-modal'
      centered
    >
      <Text lh={1.5} size={16} fw={400}>
        Chai cannot train on {files.length} out of {totalCount} sources you added because they
        exceed the supported size limits: 10 MB for Google Sheets and 50 MB for Google Docs, Google
        Slides, Google Drive files, etc.
      </Text>
      <Text lh={1.5} size={16} fw={400} mt={10}>
        Unsupported sources:
      </Text>
      <ScrollArea type='auto' scrollbarSize={6}>
        <div className={styles.filesWrapper}>
          {files.map((file, index) => {
            const { icon, alt } = returnIcon(file.type);
            return (
              <div className={styles.fileUploaderItem} key={index}>
                <Image src={icon} alt={alt} h={38} w={38} />
                <div className='font-poppins truncated-text'>{file.name}</div>
              </div>
            );
          })}
        </div>
      </ScrollArea>
    </Modal>
  );
};

UnproccessedFilesModal.propTypes = {
  files: arrayOf(
    shape({
      name: string.isRequired,
      type: string.isRequired
    })
  ).isRequired,
  totalCount: number.isRequired,
  onClose: func.isRequired
};

export default UnproccessedFilesModal;
