import PropTypes from 'prop-types';
import { Modal, Text, Flex } from '@mantine/core';
import Button from 'components/Button';
import styles from './styles.module.scss';

const ConfirmationModal = (props) => {
  const { conn, selectedConnector, onClose, installCta, connectCta } = props;

  return (
    <Modal
      opened={true}
      onClose={onClose}
      centered
      size='40%'
      className='mobile-modal'
      classNames={{
        title: styles.modalTitle
      }}
      title={`Installed Chat Aid on ${conn.title}?`}
    >
      <Text className={styles.text}>
        {`To connect Chat Aid with your ${conn.title} account, you must first install Chat Aid on your
        ${conn.title} dashboard.`}
      </Text>

      <Flex justify='end' align='end' direction='row' gap={14} mt={70}>
        <Button onClick={installCta} outlined={true}>
          Install now
        </Button>
        <Button
          onClick={connectCta}
          disabled={selectedConnector === conn.name}
          variance={conn.isConnected ? 'danger' : 'primary'}
        >
          Continue connecting
        </Button>
      </Flex>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  conn: PropTypes.object.isRequired,
  selectedConnector: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  installCta: PropTypes.func.isRequired,
  connectCta: PropTypes.func.isRequired
};

export default ConfirmationModal;
