import PropTypes from 'prop-types';
import { Image } from '@mantine/core';
import MessageIcon from 'assets/ask-chai/message-icon1.svg';
import { returnOtherIntegrationsIcon } from 'utils/utilities';
import { PROMPT_IDEA_TYPES } from 'utils/constants';
import styles from './styles.module.scss';

const PromptCard = (props) => {
  const { prompt, documentName, provider, type, category, setShowPromptIdeas, questionId } = props;

  function handleAskAlbusClick() {
    setShowPromptIdeas(false);
    let newUrl = `/ask-chai?textInput=${prompt}&forcedQueryRoute=${type}`;
    if (type === PROMPT_IDEA_TYPES.COMPANY_WIKI) {
      newUrl += `&questionId=${questionId}`;
    }
    window.history.replaceState(null, '', newUrl);
  }

  return (
    <div className={styles.cardContainer} onClick={handleAskAlbusClick}>
      {type === PROMPT_IDEA_TYPES.OPEN_AI && <div className={styles.categoryText}>{category}</div>}
      <div className={styles.promptText}>{prompt}</div>
      <div className={styles.footer}>
        <div className={styles.footerText}>
          {type === PROMPT_IDEA_TYPES.COMPANY_WIKI && (
            <>
              <Image src={returnOtherIntegrationsIcon(provider)} w={14} />
              <div className={styles.documentNameText}>{documentName}</div>
            </>
          )}
        </div>
        <div className={styles.footerText} onClick={handleAskAlbusClick}>
          <Image src={MessageIcon} w={14} />
          <div className={styles.askAlbusText}>Ask Chai</div>
        </div>
      </div>
    </div>
  );
};

PromptCard.propTypes = {
  questionId: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  documentName: PropTypes.string,
  provider: PropTypes.string,
  type: PropTypes.string.isRequired,
  category: PropTypes.string,
  setShowPromptIdeas: PropTypes.func.isRequired
};

PromptCard.defaultProps = {
  category: '',
  documentName: '',
  provider: ''
};

export default PromptCard;
