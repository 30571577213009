import { toast } from 'react-toastify';
import api from './api';
import { END_POINT } from 'utils/apiEndpoints';

const surveyReports = api.injectEndpoints({
  endpoints: (build) => ({
    fetchSurveyReports: build.query({
      query: (params) => ({
        url: END_POINT.SURVEY_REPORTS.FETCH_SURVEY_REPORTS,
        method: 'GET',
        params
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        } else {
          toast.error('Failed to fetch survey reports');
        }
      },
      transformErrorResponse: (response) => {
        toast.error(response.data.message);
      }
    }),

    fetchReportTables: build.query({
      query: (params) => ({
        url: END_POINT.SURVEY_REPORTS.FETCH_REPORT_TABLES,
        method: 'GET',
        params
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        } else {
          toast.error('Failed to fetch report tables');
        }
      },
      transformErrorResponse: (response) => {
        toast.error(response.data.message);
      },
      providesTags: ['SurveyReportTables']
    }),

    generateTablesDescription: build.mutation({
      query: (documentId) => ({
        url: END_POINT.SURVEY_REPORTS.GENERATE_DESCRIPTION,
        method: 'PATCH',
        body: { documentId }
      }),
      transformResponse: (response) => {
        if (response.ok) {
          toast.success(response.message);
        } else {
          toast.error('Failed to generate tables description');
        }
      },
      transformErrorResponse: (response) => {
        toast.error(response.data.message);
      },
      providesTags: ['SurveyReportTables']
    }),

    deleteTable: build.mutation({
      query: (payload) => ({
        url: END_POINT.SURVEY_REPORTS.DELETE_TABLE,
        method: 'DELETE',
        body: payload
      }),
      transformResponse: (response) => {
        if (response.ok) {
          toast.success(response.message);
        } else {
          toast.error('Something went wrong!');
        }
      },
      transformErrorResponse: () => {
        toast.error('Failed to delete table');
      },
      invalidatesTags: ['SurveyReportTables']
    }),

    updateTable: build.mutation({
      query: ({ documentId, tableData, tableName }) => ({
        url: END_POINT.SURVEY_REPORTS.UPDATE_TABLE,
        method: 'PATCH',
        body: { tableData, documentId, tableName }
      }),
      transformResponse: (response) => {
        if (response.ok) {
          toast.success(response.message);
        } else {
          toast.error('Failed to update table content');
        }
      },
      transformErrorResponse: (response) => {
        toast.error(response.data.message);
      },
      invalidatesTags: ['SurveyReportTables']
    }),

    generateComparisonDataset: build.mutation({
      query: (payload) => ({
        url: END_POINT.SURVEY_REPORTS.GENERATE_COMPARISON_DATASET,
        method: 'POST',
        body: payload
      }),
      transformResponse: (response) => {
        if (response.ok) {
          toast.success(response.message);
        } else {
          toast.error('Failed to generate key points');
        }
      },
      transformErrorResponse: (response) => {
        toast.error(response.data.message);
      }
    })
  })
});

export const {
  useFetchSurveyReportsQuery,
  useFetchReportTablesQuery,
  useGenerateTablesDescriptionMutation,
  useDeleteTableMutation,
  useUpdateTableMutation,
  useGenerateComparisonDatasetMutation
} = surveyReports;

export default surveyReports;
