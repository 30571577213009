import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, TextInput } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { current } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import api from 'redux/services/api';
import integrationsApi, {
  useDisconnectSourcesMutation,
  useModifySourceMutation,
  useTrainSourcesMutation,
  useUpdateDescriptionMutation
} from 'redux/services/integrations';
import UI from './UI';
import RemoveSource from '../RemoveSource';
import AutoTrainModal from '../AutoTrainModal';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import EditDescriptionModal from 'pages/Integrations/EditDescriptionModal';
import { stringDecoder } from 'utils/helpers';
import styles from './styles.module.scss';

const ActionMenu = (props) => {
  const {
    item,
    hideActionMenu,
    disableRetrain,
    showManageResources,
    hideDisconnect,
    disableDisconnect,
    disableAutoRetrain,
    showManageSitemapSources,
    handleSitemapManageSource,
    disableAutoRetrainMessage,
    hideRetrainNow,
    hideManageAutoRetrain,
    messageSourceDeleteModal,
    showManageSourceForWeb,
    showEditName,
    sourceIcon,
    metaPayload,
    showRetryAdding,
    setTotalItems
  } = props;

  const { user } = useSelector((state) => state.user);
  const [showAutoTrainModal, setShowAutoTrainModal] = useState(false);
  const [showRemoveSourceModal, setShowRemoveSourceModal] = useState(false);
  const [showEditNameModal, setShowEditNameModal] = useState(false);
  const [editNameValue, setEditNameValue] = useState('');
  const { teamId, provider } = useParams();
  const [disconnectSource, status] = useDisconnectSourcesMutation();
  const [trainSource, trainStatus] = useTrainSourcesMutation();
  const [showEditDescriptionModal, setShowEditDescriptionModal] = useState(false);
  const [updateDescription, { isLoading }] = useUpdateDescriptionMutation();
  const dispatch = useDispatch();
  const [modifySource] = useModifySourceMutation();

  async function handleEditName() {
    const response = await modifySource({
      name: editNameValue,
      documentMongoIds: [item.id],
      meta: metaPayload
    });
    if (response?.data?.ok) {
      toast.success(response.data.message);
      setShowEditNameModal(false);
    }
  }

  if (showEditNameModal) {
    return (
      <DeleteWarningModal
        open={showEditNameModal}
        onClose={() => setShowEditNameModal(false)}
        header='Edit source name'
        title=''
        body={
          <TextInput
            label='Enter name'
            placeholder={item.originalName}
            value={editNameValue}
            onChange={(e) => setEditNameValue(e.target.value)}
          />
        }
        handleCancel={() => {
          setEditNameValue(null);
          setShowEditNameModal(false);
        }}
        handleDelete={handleEditName}
        disableRemove={!editNameValue || editNameValue === item.originalName}
        removeText='Save'
        customClass={styles}
      />
    );
  }

  const handleAutoRetrain = async (freq) => {
    const payload = {
      documentMongoIds: [item.id],
      retrain: freq,
      teamId,
      provider: metaPayload.provider,
      trainType: 'Force refresh by user',
      meta: metaPayload
    };

    if (metaPayload.provider === 'web') {
      payload.includeAllSubDocs = true;
    }
    const response = await trainSource(payload);

    if (response?.data?.ok) {
      toast.success('Updated auto-retrain frequency successfully');
      setShowAutoTrainModal(false);

      if (setTotalItems) {
        setTotalItems((prev) =>
          prev.map((doc) => {
            if (doc.id === item.id) {
              return {
                ...doc,
                trainingFrequency: freq
              };
            }
            return doc;
          })
        );
      }
    }
  };

  if (showAutoTrainModal) {
    return (
      <AutoTrainModal
        opened={showAutoTrainModal}
        onClose={() => {
          setShowAutoTrainModal(false);
        }}
        disabled={trainStatus.isLoading}
        onConfirm={(value) => handleAutoRetrain(value)}
        sourceName={stringDecoder(item.originalName || item.name)}
        defaultValue={item.trainingFrequency}
      />
    );
  }

  async function handleDescriptionSave(description) {
    try {
      const payload = {
        teamId,
        description,
        documentId: item.id
      };
      const { data } = await updateDescription(payload);
      if (data?.ok) {
        setShowEditDescriptionModal(false);
        dispatch(integrationsApi.util.invalidateTags(['SourcesByProvider']));
        toast.success(data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  if (showEditDescriptionModal) {
    return (
      <EditDescriptionModal
        defalutValue={item?.description?.user}
        placeholder="Write a description about the contents of this source. This will help Chat Aid understand your information better and improve answer accuracy, so don't be shy! Write away..."
        icon={sourceIcon}
        title={item.isSlackMessage ? item.title : item.originalName || item.name}
        resetValue={item?.description?.ai}
        onSave={handleDescriptionSave}
        onClose={() => setShowEditDescriptionModal(false)}
        isLoading={isLoading}
      />
    );
  }

  const handleDelete = async () => {
    const payload = {
      provider,
      teamId,
      documentMongoIds: [item.id]
    };

    const response = await disconnectSource(payload);
    if (response?.data?.ok) {
      toast.success(response.data.message);
      setShowRemoveSourceModal(false);
      dispatch(integrationsApi.util.invalidateTags(['SourcesByProvider']));

      if (setTotalItems) {
        setTotalItems((prev) => prev.filter((doc) => doc.id !== item.id));
      }
    }
  };

  if (showRemoveSourceModal) {
    if (messageSourceDeleteModal) {
      return (
        <DeleteWarningModal
          open={showRemoveSourceModal}
          header={
            <Text size='lg' fw={600}>
              Are you sure you want to remove this source?
            </Text>
          }
          title={
            <Text size='md'>
              An admin marked this message important. When you remove this source, I will neither
              consider it important and nor refer to it while answering questions.
            </Text>
          }
          onClose={() => {
            setShowRemoveSourceModal(false);
          }}
          removeText='Remove'
          handleCancel={() => {
            setShowRemoveSourceModal(false);
          }}
          handleDelete={handleDelete}
          disabled={status.isLoading || status.isSuccess}
        >
          <Text size='sm'>
            <Text display='contents' size='sm' c='rgba(0, 0, 0, 0.50)'>
              PS:
            </Text>
            &nbsp; if i am training on the channel this message was sent in, I might still refer to
            the message content.
          </Text>
        </DeleteWarningModal>
      );
    }

    return (
      <RemoveSource
        opened={showRemoveSourceModal}
        onClose={() => {
          setShowRemoveSourceModal(false);
        }}
        handleDelete={handleDelete}
        item={item}
        disableButton={status.isLoading || status.isSuccess}
      />
    );
  }

  const handleRetrain = async () => {
    const response = await trainSource({
      documentMongoIds: [item.id],
      retrain: 'now',
      teamId,
      trainType: 'Force refresh by user',
      provider: metaPayload.provider
    }); //freq - now :- immediate, manual retrain.

    dispatch(
      api.util.updateQueryData('fetchSources', { ...metaPayload }, (draft) => {
        const cacheData = current(draft);
        let sourcesCopy = cloneDeep(cacheData.data);

        const index = sourcesCopy.findIndex((source) => source.id === item.id);
        sourcesCopy[index] = {
          ...sourcesCopy[index],
          status: 'processing'
        };

        return { ...cacheData, data: sourcesCopy };
      })
    );

    if (response?.data?.ok) {
      toast.success(response.data.message);
    }
  };

  const handleManageConnection = () => {
    window.open(
      `${import.meta.env.VITE_API_URL}/auth/notion/redirect?userId=${user._id}&teamId=${teamId}`,
      '_self'
    );
  };

  function editDescriptionClickHandler(e) {
    e.stopPropagation();
    setShowEditDescriptionModal(true);
  }

  function handleManageSourceForWeb(e) {
    e.stopPropagation();
    item.handleManageSourcesClick();
  }

  return (
    <UI
      hideActionMenu={hideActionMenu}
      provider={provider}
      showRetryAdding={showRetryAdding}
      handleDisconnect={() => setShowRemoveSourceModal(true)}
      handleRetrain={handleRetrain}
      disableRetrain={disableRetrain || trainStatus.isLoading}
      handleAutoRetrain={() => setShowAutoTrainModal(true)}
      handleManageConnection={handleManageConnection}
      disableManageSource={item.disableManageSource}
      showManageResources={showManageResources}
      hideDisconnect={hideDisconnect}
      disableDisconnect={disableDisconnect}
      disableAutoRetrain={disableAutoRetrain}
      showManageSitemapSources={showManageSitemapSources}
      handleSitemapManageSource={handleSitemapManageSource}
      disableAutoRetrainMessage={disableAutoRetrainMessage}
      hideRetrainNow={hideRetrainNow}
      hideManageAutoRetrain={hideManageAutoRetrain}
      editDescriptionClickHandler={editDescriptionClickHandler}
      showManageSourceForWeb={showManageSourceForWeb}
      showEditName={showEditName}
      setShowEditNameModal={setShowEditNameModal}
      handleManageSourceForWeb={handleManageSourceForWeb}
    />
  );
};

ActionMenu.defaultValue = {
  showManageSitemapSources: false,
  handleSitemapManageSource: () => null,
  hideRetrainNow: false,
  hideManageAutoRetrain: false,
  showRetryAdding: false,
  setTotalItems: () => null,
  hideActionMenu: false
};

ActionMenu.propTypes = {
  item: PropTypes.object,
  showRetryAdding: PropTypes.bool,
  hideDisconnect: PropTypes.bool,
  showManageResources: PropTypes.bool,
  handleDisconnect: PropTypes.func,
  disableRetrain: PropTypes.bool,
  handleDisableAutoRetrain: PropTypes.func,
  disableDisconnect: PropTypes.bool,
  disableAutoRetrain: PropTypes.bool,
  hideActionMenu: PropTypes.bool,
  showManageSitemapSources: PropTypes.bool,
  handleSitemapManageSource: PropTypes.func,
  disableAutoRetrainMessage: PropTypes.string,
  hideRetrainNow: PropTypes.bool,
  hideManageAutoRetrain: PropTypes.bool,
  messageSourceDeleteModal: PropTypes.bool,
  showManageSourceForWeb: PropTypes.bool,
  showEditName: PropTypes.bool,
  sourceIcon: PropTypes.string,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: PropTypes.shape({
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    })
  }).isRequired,
  setTotalItems: PropTypes.func
};

export default ActionMenu;
