import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Text } from '@mantine/core';
import Layout from 'components/Layout';
import Announcement from 'components/Announcement';
import IntegrationList from './IntegrationList';
import TeamFilter from './TeamFilter';
import LearnDescriptionModal from './LearnDescriptionModal';
import EditDescriptionModal from './EditDescriptionModal';
import integrationsApi, { useUpdateDescriptionMutation } from 'redux/services/integrations';
import { eventTracking } from 'components/EventAnalyticsProvider/utils';
import userApi, { useUpdateUserMutation } from 'redux/services/user';
import { startWikiTutorial } from './Tutorial';
import { NAVBAR_ROUTES } from 'utils/constants';
import useShowTutorial from 'custom_hooks/useShowTutorial';
import { useGetSettingsQuery, useGetTeamsQuery } from 'redux/services/settings';
import { WIKI_BANNER } from './utils';
import styles from './integrations.module.scss';

const Integrations = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const success = searchParams.get('success');
  const { teamId } = useParams();
  const [selectedTeam, setSelectedTeam] = useState();
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddConnectionsCTA, setShowAddConnectionsCTA] = useState(true);
  const [showLearnDescriptionModal, setShowLearnDescriptionModal] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const dispatch = useDispatch();
  const [updateUser] = useUpdateUserMutation();
  const [updateDescription, { isLoading }] = useUpdateDescriptionMutation();
  const [editDescriptionModalData, setEditDescriptionModalData] = useState({
    show: false,
    data: {}
  });

  const { data: { training } = {}, isLoading: areSettingsLoading = true } = useGetSettingsQuery();
  const {
    data: { teams = [] } = {
      teams: []
    },
    isFetching
  } = useGetTeamsQuery();

  useShowTutorial(startWikiTutorial);

  useEffect(() => {
    dispatch(userApi.util.invalidateTags(['User']));
  }, []);

  useEffect(() => {
    if (success === 'false') {
      toast.error('Something went wrong, try again.');
      navigate(NAVBAR_ROUTES.INTEGRATIONS);
    }
  }, []);

  useEffect(() => {
    if (user._id) {
      eventTracking('User opened dashboard', user, 'Wiki');
    }

    const bannerObj = user?.featuresExploredFlags?.filter(
      (flag) => flag.featureName === WIKI_BANNER
    )?.[0];

    if (bannerObj?.state === 'active') {
      setShowBanner(true);
    }
  }, [user]);

  useEffect(() => {
    if (!isFetching && user._id) {
      let teamOptions = teams.map((team) => ({
        value: team.teamId,
        label: team.name,
        companywiki: team.companyWiki
      }));

      if (!user.isAdmin) {
        teamOptions = teamOptions.filter((team) => !team.companywiki);
      }

      setOptions(teamOptions);
      setSelectedTeam(
        user.isAdmin
          ? teamId || teamOptions.find((team) => team.companywiki).value
          : teamOptions[0]?.value
      );
    }
  }, [teams, user]);

  useEffect(() => {
    if (!areSettingsLoading && !training) {
      navigate(NAVBAR_ROUTES.FIRST_USER_LOGIN);
    }
  }, [training]);

  const handleDetailsClick = (conn) => {
    let url = `/integrations/${conn.name}`;
    url += `/${selectedTeam}`;
    navigate(url);
  };

  const handleFilterChange = (value) => {
    setSelectedTeam(value);
    const url = `/integrations/${value}`;
    window.history.pushState(null, '', url);
  };

  function learnMoreCtaClickHandler() {
    setShowLearnDescriptionModal(true);
  }

  function editDescriptionClickHandler(e, connectorData) {
    e.stopPropagation();
    setEditDescriptionModalData({
      show: true,
      data: { ...connectorData }
    });
  }

  function bannerCloseCtaClickHandler() {
    updateUser({
      featuresExploredFlags: [
        {
          featureName: WIKI_BANNER,
          state: 'inactive'
        }
      ]
    });
    setShowBanner(false);
  }

  async function handleDescriptionSave(description) {
    try {
      const payload = {
        teamId: selectedTeam,
        description,
        provider: editDescriptionModalData.data.name
      };
      const { data } = await updateDescription(payload);

      if (data.ok) {
        setEditDescriptionModalData({ show: false, data: {} });
        dispatch(integrationsApi.util.invalidateTags(['Providers']));
        toast.success(data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  return (
    <Layout pageTitle={'Wiki'}>
      {showLearnDescriptionModal && (
        <LearnDescriptionModal onClose={() => setShowLearnDescriptionModal(false)} />
      )}
      {editDescriptionModalData.show && (
        <EditDescriptionModal
          defalutValue={editDescriptionModalData.data?.description?.user}
          placeholder="Write a description about how you use this app at your company. This will help Chat Aid understand your information better and improve answer accuracy. So don't be shy, write away..."
          icon={editDescriptionModalData.data.icon}
          title={editDescriptionModalData.data.title}
          subText={options?.filter((option) => option.value === selectedTeam)?.[0]?.label}
          resetValue={editDescriptionModalData.data?.description?.ai}
          showDefaultTag={
            options?.filter((option) => option.value === selectedTeam)?.[0]?.companywiki
          }
          onSave={handleDescriptionSave}
          onClose={() => setEditDescriptionModalData({ show: false, data: {} })}
          isLoading={isLoading}
        />
      )}
      {showBanner && (
        <Announcement
          title='Improve answer accuracy with descriptions'
          handleClose={bannerCloseCtaClickHandler}
          customClass={styles.bannerClass}
          showCloseCta
          textCopy={
            <>
              When you describe how you use your connections and summarize the information in your
              sources, Chat Aid understands your company better. This helps him write more accurate
              and reliable answers as well.&nbsp;
              <Text
                td='underline'
                size='sm'
                c='primaryGreen.3'
                display='inline-flex'
                className='cursor-pointer'
                onClick={learnMoreCtaClickHandler}
              >
                Learn more.
              </Text>
            </>
          }
        />
      )}
      <div className={styles.integrationsPage}>
        <TeamFilter
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          options={options}
          handleChange={handleFilterChange}
          disabled={isFetching}
          showAddConnectionsCTA={showAddConnectionsCTA}
        />
        <div className={styles.integrationsListWrapper} id='connectors-list'>
          {selectedTeam && (
            <IntegrationList
              searchTerm={searchTerm}
              handleDetailsClick={handleDetailsClick}
              teamId={selectedTeam}
              setShowAddConnectionsCTA={setShowAddConnectionsCTA}
              editDescriptionClickHandler={editDescriptionClickHandler}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Integrations;
