import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import userApi from 'redux/services/user';
import About from '../About';
import Connectors from '../Connectors';
import AddASource from '../AddASource';
import { NAVBAR_ROUTES } from 'utils/constants';

const OnboardingBodyComponent = (props) => {
  const { step, isLoading, handleAboutSubmit, handleStepChange, provider, active } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showStep = (active) => {
    switch (active) {
      case 0:
        return <About step={step} isLoading={isLoading} handleStepChange={handleAboutSubmit} />;

      case 1:
        return <Connectors handleStepChange={handleStepChange} />;

      case 2:
        return <AddASource handleStepChange={handleStepChange} provider={provider} />;

      case 3: {
        dispatch(userApi.util.invalidateTags(['User']));
        navigate(
          `${NAVBAR_ROUTES.ASK_CHAI}?on-boarding=true&provider=${provider}&showWelcomeModal=true`
        );
        return;
      }
    }
  };

  return <>{showStep(active)}</>;
};

OnboardingBodyComponent.propTypes = {
  step: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleAboutSubmit: PropTypes.func.isRequired,
  handleStepChange: PropTypes.func.isRequired,
  provider: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  active: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired
};

export default OnboardingBodyComponent;
