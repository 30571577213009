import { stepConstructor, tutorialIntiator } from 'components/TutorialWrapper';
import OneIcon from 'assets/tutorial-icons/escalations/one.svg';

export function startEscalationsTutorial(showTutorialModal) {
  const steps = [
    {
      popover: {
        description: stepConstructor(
          'What are escalations?',
          `When Chai answers a question incorrectly or is unable to help, your colleague can escalate their question to an admin.<br/>When they escalate a question, Chai notifies admins and asks them to intervene. These escalated questions are displayed here.`,
          OneIcon
        )
      }
    },
    {
      element: '#escaltaions-tab-NEEDS_ATTENTION',
      popover: {
        description: stepConstructor(
          'Needs attention',
          `All escalated questions are displayed here. Admins can view these questions, answer them and teach Chai the correct answer.<br/>Questions escalated in public channels and private channels show up here.`
        )
      }
    },
    {
      element: '#escaltaions-tab-EVERYTHING_ELSE',
      popover: {
        description: stepConstructor(
          'Everything else',
          `When your colleague does not escalate a question, it is displayed here. To maintain privacy of information, only questions asked on public channels and private channels you are a member of are displayed here.<br/><br/>💡 When you answer an escalated question or resolve it, it is moved to this tab.`
        )
      }
    }
  ];

  tutorialIntiator(steps, showTutorialModal);
}
