import { bool, object } from 'prop-types';
import { Tabs } from '@mantine/core';
import AnswerPreview from '../AnwerPreview';
import styles from '../../../../styles.module.scss';

const AnswerTabs = ({ data, screen767 }) => {
  return (
    <Tabs defaultValue='admin' className='tabs-style-1'>
      <Tabs.List>
        <Tabs.Tab value='admin'>Admin&apos;s answer</Tabs.Tab>
        <Tabs.Tab value='albus'>Chai&apos;s answer</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value='admin' pt='xs'>
        <div className={styles.accordianContentWrapper}>
          <AnswerPreview
            data={data}
            screen767={screen767}
            answer={<pre>{data?.completion?.admin}</pre>}
            hideSources={true}
            adminAnswer={true}
          />
        </div>
      </Tabs.Panel>

      <Tabs.Panel value='albus' pt='xs'>
        <div className={styles.accordianContentWrapper}>
          <AnswerPreview
            data={data}
            screen767={screen767}
            answer={
              data?.completion?.[data.completedUsing] || 'We could not find an answer in wiki'
            }
          />
        </div>
      </Tabs.Panel>
    </Tabs>
  );
};

AnswerTabs.defaultValue = {
  data: {}
};

AnswerTabs.propTypes = {
  data: object,
  screen767: bool.isRequired
};

export default AnswerTabs;
