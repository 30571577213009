import { Flex } from '@mantine/core';
import AppBanner from '../Components/AppBanner';
import StepGuide from '../Components/StepGuide';
import ZapierIcon from 'assets/icons/zapier-icon.svg';
import styles from './styles.module.scss';

const Zapier = () => {
  const supportedApps = ['Google Drive'];

  const video = {
    id: 1,
    url: 'https://video.gumlet.io/614ac1fa71ee9511b70253de/65cf3d52f75a6dee5d0bfd73/main.mp4'
  };

  const steps = [
    {
      step: 1,
      title: 'Create a Zap',
      description: 'Trigger a Zap when a new file or folder is added to Google Drive.',
      showWikiIdCta: false
    },

    {
      step: 2,
      title: 'Create an action for Chai',
      description: (
        <>
          Select the event <span className={styles.boldDescription}>Train file</span>.
        </>
      ),
      showWikiIdCta: false
    },

    {
      step: 3,
      title: 'Allow Zapier access to your Chat Aid account',
      description: 'Enter the API key and select destination Wiki to continue.',
      showWikiIdCta: false
    }
  ];

  function handleConnect() {
    window.open(import.meta.env.VITE_ZAPIER_APP_URL, '_blank');
  }

  return (
    <Flex gap={24} className={styles.wrapper} direction='column' w='100%'>
      <AppBanner
        logo={ZapierIcon}
        appName='Zapier'
        description='Trigger a Zap when a new file is added to Google Drive™. Add an action to train Chat Aid on the new file.'
        supportedApps={supportedApps}
        handleConnect={handleConnect}
      />
      <StepGuide
        title='Automatically train Chai on a file when added to Google Drive'
        steps={steps}
        video={video}
      />
    </Flex>
  );
};

export default Zapier;
