import { stepConstructor, tutorialIntiator } from 'components/TutorialWrapper';
import OneIcon from 'assets/tutorial-icons/teams/one.svg';
import ThreeIcon from 'assets/tutorial-icons/teams/three.svg';
import FourIcon from 'assets/tutorial-icons/teams/four.svg';

export function startTeamsTutorials(showTutorialModal) {
  const steps = [
    {
      popover: {
        description: stepConstructor(
          'What are teams?',
          `Teams in Chai allow you to restrict information among specific groups of employees.<br/>For example, technical documentation is only relevant to your engineering team. You would want to confine that information to them.<br/>You can do this by creating teams.`,
          OneIcon
        )
      }
    },
    {
      element: '#create-team',
      popover: {
        description: stepConstructor(
          'Create your first team',
          `Let's create a team! This could be a department, colleagues working together on a project or specific group of employees.`
        )
      }
    },
    {
      popover: {
        description: stepConstructor(
          'Select team admins and teammates',
          `Teammates have access to the information in this team. When a teammate asks a question, Chai will search for answers in this team along with other teams they are a part of.<br/><br/>💡 Team admins are not teammates by default. Add them as teammates if you like.`,
          ThreeIcon
        )
      }
    },
    {
      popover: {
        description: stepConstructor(
          'Add wiki data to your team',
          `Every team comes with its very own wiki. Once you create a team, you can add sources with information relevant only to teammates in this team.<br/>You can also manage teammates and team admins here.`,
          FourIcon
        )
      }
    }
  ];

  tutorialIntiator(steps, showTutorialModal);
}
