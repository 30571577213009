import { toast } from 'react-toastify';
import { current } from '@reduxjs/toolkit';
import { updateAddOnInteractionsLeft } from 'redux/features/planSlice';
import api from './api';
import {
  CHAT_HISTORY,
  CHAT,
  INTEGRATIONS_SOURCES,
  PROMPT_IDEAS,
  REQUEST_LICENSE,
  ADD_ON_QUESTIONS,
  CHATS_COMPLETION
} from 'utils/apiEndpoints';
import { handleApiError } from 'utils/helpers';

const askChaiApi = api.injectEndpoints({
  endpoints: (build) => ({
    getChatsHistory: build.query({
      query: () => ({
        url: CHAT_HISTORY + '?origin=web',
        method: 'GET'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data.questions;
        } else {
          toast.error('Failed to fetch chats history.');
        }
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),
    getConversation: build.query({
      query: (params) => ({
        url: CHAT,
        method: 'GET',
        params
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data.questions;
        } else {
          toast.error('Failed to fetch conversation.');
        }
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),
    getIntegrationsSources: build.query({
      query: () => ({
        url: `${INTEGRATIONS_SOURCES}?withQuestions=true`
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data.documents;
        } else {
          toast.error('Failed to fetch integrations sources.');
        }
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),
    getPromptIdeas: build.query({
      query: (params) => ({
        url: PROMPT_IDEAS,
        params
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        } else {
          toast.error('Failed to fetch prompt ideas.');
        }
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),
    deleteChat: build.mutation({
      query: (payload) => ({
        url: CHAT + '/' + payload.questionId,
        method: 'DELETE'
      }),
      async onQueryStarted(formData, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;

          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getChatsHistory', undefined, (draft) => {
                const chatsHistory = current(draft);
                const { dateRange } = formData;
                const updatedChatHistory = [...chatsHistory[dateRange]];
                const updatedChatHistoryWithoutDeleted = updatedChatHistory.filter(
                  (item) => item.promptId !== formData.questionId
                );

                draft[dateRange] = updatedChatHistoryWithoutDeleted;

                return draft;
              })
            );

            toast.success('Chat deleted successfully');
          } else {
            toast.error('Failed to delete the chat');
          }
          return Promise.resolve();
        } catch ({ error }) {
          handleApiError(error);
          return Promise.resolve();
        }
      }
    }),
    requestLicense: build.mutation({
      query: () => ({
        url: REQUEST_LICENSE,
        method: 'POST'
      }),
      invalidatesTags: ['User'],
      transformErrorResponse: (error) => handleApiError(error)
    }),
    addOnQuestions: build.mutation({
      query: () => ({
        url: ADD_ON_QUESTIONS,
        method: 'POST'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            let addOnInteractionsLeft;

            switch (process.env.NODE_ENV) {
              case 'production':
                addOnInteractionsLeft = 100;
                break;
              case 'development':
              case 'acceptance': // TODO: this is temporary, remove this after acceptance is shut down
                addOnInteractionsLeft = 10;
                break;
              default:
                addOnInteractionsLeft = 5;
                break;
            }

            dispatch(updateAddOnInteractionsLeft({ addOnInteractionsLeft }));

            setTimeout(() => {
              dispatch(api.util.invalidateTags(['Plan']));
            }, 1000);

            toast.success(response.message);
          } else {
            toast.error(response.message);
          }
          return Promise.resolve();
        } catch ({ error }) {
          handleApiError(error);
          return Promise.resolve();
        }
      }
    }),
    generateAnswer: build.mutation({
      query: (body) => ({
        url: `${CHATS_COMPLETION}/web`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Completion'],
      transformErrorResponse: (error) => handleApiError(error)
    }),
    getCompletion: build.query({
      query: (promptId) => ({ url: `${CHATS_COMPLETION}/web/${promptId}` }),
      providesTags: ['Completion'],
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        } else {
          toast.error('Failed to fetch answer.');
        }
      },
      transformErrorResponse: (error) => handleApiError(error)
    })
  })
});

export const {
  useGetChatsHistoryQuery,
  useGetConversationQuery,
  useGetIntegrationsSourcesQuery,
  useGetPromptIdeasQuery,
  useDeleteChatMutation,
  useRequestLicenseMutation,
  useAddOnQuestionsMutation,
  useGenerateAnswerMutation,
  useGetCompletionQuery
} = askChaiApi;

export default askChaiApi;
