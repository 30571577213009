import { Anchor, Text } from '@mantine/core';

export const SOURCES_STATUS = {
  ACTIVE: 'active',
  PARTIALLY_ACTIVE: 'partially active',
  PROCESSING: 'processing',
  ERROR: 'error',
  EXCLUDED: 'excluded'
};

export const SOURCE_ACTION = {
  RETRAIN: 'retrain',
  EXCLUDE: 'exclude',
  INCLUDE: 'include',
  RECRAWL: 'recrawl',
  EXCLUDE_WILL_REMOVE_SOURCE: 'exclude_will_remove_source'
};

export const CRAWL_DEPTH = {
  PARENT: 0,
  CHILDREN: 3
};

export const FILTER_OPTIONS = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Web pages',
    value: 'website'
  },
  {
    label: 'Sitemap',
    value: 'sitemap'
  }
];

const TABS = {
  WEBSITE: 'website',
  SITEMAP: 'sitemap'
};

export default TABS;

export const ERROR_TOOLTIPS = {
  Restricted: (
    <Text size='12px' c='#FFFFFF'>
      Not a public page; Chat Aid can only train on public web pages
    </Text>
  ),
  Failed: (
    <Text size='12px' c='#FFFFFF'>
      Something went wrong; reach out to us at&nbsp;
      <Anchor underline='always' c='#08B0F9' href='mailto:support@chataid.com'>
        support@chataid.com
      </Anchor>
    </Text>
  ),

  Invalid: (
    <Text size='12px' c='#FFFFFF'>
      Use valid format - https://www.example.com/sitemap.xml/
    </Text>
  ),

  'Does not exist': (
    <Text size='12px' c='#FFFFFF'>
      Cannot find this web page
    </Text>
  )
};
