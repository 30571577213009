import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Text } from '@mantine/core';
import authenticate from '@truto/truto-link-sdk';
import Layout from 'components/Layout';
import SubmitRequestSection from './Components/SubmitRequest/View';
import AlreadyConnectedModal from 'components/Modals/AlreadyConnectedModal';
import RequestModal from 'components/Modals/RequestModal';
import ConnectorDetailsModal from 'pages/ControlPanel/Components/ConnectorDetailsModal';
import SlackConnectModal from 'components/Modals/SlackConnectModal';
import InformationPanel from './Components/InformationPanel/View';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import SupportedApps from './Components/SupportedApps/View.jsx';
import { usePostSlackConnectMutation } from 'redux/services/slack-connect';
import { useLazyGetTrutoLinkTokenQuery } from 'redux/services/truto';
import { useDisconnectProviderMutation } from 'redux/services/integrations';
import { useLazyGetUserQuery } from 'redux/services/user';
import { handleApiError } from 'utils/helpers';
import { ACTIONS_SUPPORTED_CONNECTORS } from 'pages/ControlPanel/constants';
import { ENV, NAVBAR_ROUTES } from 'utils/constants';
import styles from './styles.module.scss';

const Actions = () => {
  const { user } = useSelector((state) => state.user);
  const { connections } = useSelector((state) => state.connections);
  const [postSlackConnect, { isSuccess: slackConnectSuccess }] = usePostSlackConnectMutation();
  const [getTrutoLinkTokenTrigger] = useLazyGetTrutoLinkTokenQuery();
  const [disconnectProvider] = useDisconnectProviderMutation();
  const [getUser] = useLazyGetUserQuery();
  const [showDisconnectModal, setDisconnectModal] = useState({
    state: false,
    connector: null,
    disabled: false
  });
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [alreadyConnectedModal, setAlreadyConnectedModal] = useState(false);
  const [slackConnectModal, setSlackConnectModal] = useState(false);
  const [activeTab, setActiveTab] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [retryCount, setRetryCount] = useState(0);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [selectedConnector, setSelectedConnector] = useState('');
  const navigate = useNavigate();

  const isProduction = import.meta.env.VITE_NODE_ENV === ENV.PRODUCTION;

  const data = connections.filter((connector) => {
    if (ACTIONS_SUPPORTED_CONNECTORS.includes(connector.name)) {
      return isProduction ? connector.category === 'Ticketing' && connector : connector;
    }
  });

  const apps = searchTerm
    ? [...data.filter((app) => app.title.toLowerCase().includes(searchTerm.toLowerCase()))]
    : activeTab === 'all'
    ? [...data]
    : [...data.filter((app) => app.category.toLowerCase().includes(activeTab))];

  function handleBookCall() {
    setShowRequestModal(false);
    window.open('https://calendly.com/chataid/25min', '_blank');
  }

  async function handleChatOnSlack() {
    const response = await postSlackConnect({
      emails: [user?.email]
    });
    setShowRequestModal(null);
    if (response?.data?.channelId) {
      setAlreadyConnectedModal(true);
    } else if (response?.data.ok) {
      setSlackConnectModal(true);
    }
  }

  async function handlePostConnect(connectionName) {
    try {
      const result = await getUser();
      if (user?.connections?.some((conn) => conn.provider === connectionName)) {
        return;
      }

      const connectionInitiated = {
        ...(JSON.parse(localStorage.getItem('connectionInitiated')) || {}),
        [connectionName]: true
      };
      localStorage.setItem('connectionInitiated', JSON.stringify(connectionInitiated));

      if (result?.data?.user?.connections) {
        result.data.user.connections.forEach(({ provider }) => {
          delete connectionInitiated[provider];
        });

        if (Object.keys(connectionInitiated).length === 0) {
          localStorage.removeItem('connectionInitiated');
        } else {
          localStorage.setItem('connectionInitiated', JSON.stringify(connectionInitiated));
          const backoffInterval = Math.pow(2, retryCount) * 1000;
          setRetryCount(retryCount + 1);
          setTimeout(() => handlePostConnect(connectionName), backoffInterval);
        }
      } else {
        localStorage.removeItem('provider');
      }
    } catch (error) {
      handleApiError(error);
    }
  }

  const handleConnect = async (conn) => {
    setSelectedConnector(conn.name);
    try {
      const options = {
        integration: conn.name,
        noBack: true,
        authFormat: 'oauth2',
        skipRapidForm: true,
        iframe: true
      };

      const params = { provider: conn.name, isConnect: true };
      const { data } = await getTrutoLinkTokenTrigger(params);
      setSelectedConnection(null);
      const response = await authenticate(data.linkToken, options);
      if (response?.result === 'success') {
        setSelectedConnector('');
        handlePostConnect(conn.name);
        toast.success(`${conn.title} connected successfully`);
        setSelectedConnection(conn);
      }
    } catch (error) {
      setSelectedConnector('');
      if (
        error.message === 'Not Found: Error while fetching resource - LIST ticketing/workspaces'
      ) {
        return toast.error('Unable to find the workspace by this name. Please try again.');
      }
      return toast.error(
        'Authentication error: Unable to proceed. Please check your credentials or try again.'
      );
    }
  };

  const handleDisconnect = async () => {
    setDisconnectModal({ ...showDisconnectModal, disabled: true });
    try {
      const response = await disconnectProvider(showDisconnectModal.connector.name);
      if (response.data.ok) {
        toast.success(`${showDisconnectModal.connector.title} disconnected successfully`);
        setDisconnectModal({
          state: false,
          connector: null,
          disabled: false
        });
      }
    } catch (error) {
      toast.error('Unable to disconnect, Try again!');
    }
  };

  const handleAddSource = () => {
    navigate(NAVBAR_ROUTES.INTEGRATIONS);
  };

  return (
    <Layout pageTitle='Actions'>
      <div className={styles.actionsPage}>
        <InformationPanel />
        <SupportedApps
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          apps={apps}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setSelectedConnection={setSelectedConnection}
        />
        <SubmitRequestSection setShowRequestModal={setShowRequestModal} />

        {slackConnectSuccess && (
          <SlackConnectModal opened={slackConnectModal} setOpened={setSlackConnectModal} />
        )}

        {alreadyConnectedModal && (
          <AlreadyConnectedModal
            opened={alreadyConnectedModal}
            setOpened={setAlreadyConnectedModal}
          />
        )}

        {showRequestModal && (
          <RequestModal
            setShowRequestModal={setShowRequestModal}
            handleBookCall={handleBookCall}
            handleChatOnSlack={handleChatOnSlack}
          />
        )}

        {selectedConnection && (
          <ConnectorDetailsModal
            conn={connections?.find((conn) => conn.name === selectedConnection.name) ?? {}}
            onClose={() => setSelectedConnection(null)}
            handleConnect={handleConnect}
            handleDisconnect={(conn) =>
              setDisconnectModal({
                state: true,
                connector: conn,
                disabled: false
              })
            }
            handleRequest={() => setShowRequestModal(true)}
            selectedConnector={selectedConnector}
            handleAddSource={handleAddSource}
          />
        )}

        {showDisconnectModal.state && (
          <DeleteWarningModal
            open={true}
            header={`Disconnect ${showDisconnectModal?.connector?.title}`}
            title={
              <>
                Are you sure you want to disconnect from&nbsp;
                <strong>{showDisconnectModal?.connector?.title}</strong> ?
              </>
            }
            handleDelete={handleDisconnect}
            handleCancel={() => {
              setDisconnectModal({
                state: false,
                connector: null,
                disabled: false
              });
            }}
            onClose={() => {
              setDisconnectModal({
                state: false,
                connector: null,
                disabled: false
              });
            }}
            removeText='Disconnect'
            disabled={showDisconnectModal.disabled}
          >
            <Text className={styles.text}>
              <span className={styles.fadedText}>Note:&nbsp;</span>When you disconnect, sources you
              added through this connection will be removed.
            </Text>
          </DeleteWarningModal>
        )}
      </div>
    </Layout>
  );
};

export default Actions;
